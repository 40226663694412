// Tailwind Merge
import { TOffer } from '@/types/offer.ts'
import { formatDateToString } from '@/utils/date.ts'
import { ROUTES } from '@/constants/routes.tsx'
import OfferStateBadge from '@/components/atom/OfferStateBadge'
import { EFileType } from '@/types/enum/fileType.ts'
import { IconFile } from '@tabler/icons-react'
import ListItem from '@/components/ui/ListItem'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { formatPrice } from '@/utils/price'

type OfferPrivateListItemProps = {
  className?: string
  offer: TOffer
}

const OfferPrivateListItem = ({ offer }: OfferPrivateListItemProps) => {
  const firstFile = offer.files[0]

  const _renderThumbnail = () => {
    if (firstFile?.fileType === EFileType.PHOTO || firstFile?.fileType === EFileType.VIDEO)
      return (
        <img
          src={firstFile.privateThumbnailUrl}
          alt={offer.name}
          className={'h-16 w-16 rounded-lg object-cover'}
        />
      )

    if (firstFile?.fileType === EFileType.FILE)
      return (
        <div className={'flex h-16 w-16 items-center justify-center rounded-lg bg-gray-200'}>
          <IconFile size={36} className={'text-gray-300'} />
        </div>
      )
  }

  return (
    <ListItem
      href={`${ROUTES.OFFERS}/${offer.id}`}
      leftContent={<>{_renderThumbnail()}</>}
      rightContent={
        <>
          <span className={'-mt-1 font-semibold'}>{offer.name}</span>
          <span className={'font-medium'}>{formatPrice(offer.price)} Kč</span>
          <div className={'flex flex-wrap items-center  gap-2'}>
            <OfferStateBadge offer={offer} size={'sm'} /> •
            <span className={'text-sm text-gray-500'}>{formatDateToString(offer.createdAt)}</span>•
            <span className={'flex items-center justify-center gap-1 text-sm text-gray-500'}>
              <ShoppingCartIcon width={15} />
              {offer.successfullOrdersCount}
            </span>
          </div>
        </>
      }
    />
  )
}

export default OfferPrivateListItem
