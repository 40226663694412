// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

type NavbarItemProps = {
  className?: string
  icon: React.ElementType
  label?: string
  href?: string
  onClick?: () => void
}

const NavbarItem = ({ icon, label, className, href, onClick }: NavbarItemProps) => {
  const IconComponent = icon

  const location = useLocation()

  // Determine if the current route matches the href prop
  const active = href && location.pathname.includes(href)

  const wrapperClassName = twMerge(
    'flex flex-col items-center justify-center text-gray-400',
    active && 'text-primary-500',
    className,
  )

  const content = (
    <>
      <IconComponent size={28} />
      <span className={'text-xs font-light'}>{label}</span>
    </>
  )

  const _renderItem = () => {
    if (onClick)
      return (
        <button className={wrapperClassName} onClick={onClick}>
          {content}
        </button>
      )
    if (href)
      return (
        <Link to={href} className={wrapperClassName}>
          {content}
        </Link>
      )
  }

  return (
    <li className={twMerge('m-0 flex items-center justify-center', className)}>{_renderItem()}</li>
  )
}

export default NavbarItem
