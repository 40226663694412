// React Router DOM
import { Outlet } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import Footer from '@/components/molecule/Footer'
import Logo from '@/components/atom/Logo'

type TAnonymousLayoutProps = {
  hideLogo?: boolean
}
const AnonymousLayout = ({ hideLogo }: TAnonymousLayoutProps) => {
  return (
    <>
      <body className={twMerge('flex w-full  flex-col items-center justify-center')}>
        <div className={'mb-32 flex w-full  flex-col  pt-3'} style={{ maxWidth: '600px' }}>
          <div className={'flex flex-1 flex-col gap-12 overflow-scroll overflow-y-auto '}>
            {!hideLogo && (
              <div className={'-mb-6 flex justify-center'}>
                <Logo />
              </div>
            )}
            <main className={'min-h-[70vh]'}>
              <Outlet />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  )
}

export default AnonymousLayout
