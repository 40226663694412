// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Input from '@/components/ui/Input'
import { bioSchema, usernameSchema } from '@/utils/zod.ts'
import { useEffect } from 'react'
import { TUser } from '@/types/user.ts'
import { useUpdateMyUser } from '@/api/queries/user.queries.ts'
import TextArea from '@/components/ui/TextArea'
import { inputsValidationConfig } from '@/constants/config.ts'
import CustomTooltip from '@/components/ui/CustomTooltip'
import VisibilityToggleGroup from '@/components/form/VisibilityToggleGroup'

type RegistrationFormProps = {
  className?: string
  setIsFormEdited?: (value: boolean) => void
  profile?: TUser
}

const registrationSchema = z.object({
  username: usernameSchema,
  isPublic: z.boolean(),
  bio: bioSchema,
})

export type TRegistrationSchema = z.infer<typeof registrationSchema>

const UserSettingsForm = ({ className, setIsFormEdited, profile }: RegistrationFormProps) => {
  const { mutateAsync: updateUser } = useUpdateMyUser()
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
    control,
  } = useForm<TRegistrationSchema>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {
      username: profile?.username,
      isPublic: profile?.isPublic,
      bio: profile?.bio,
    },
  })

  useEffect(() => {
    setIsFormEdited && setIsFormEdited(isDirty)
  }, [setIsFormEdited, isDirty])

  const _onSubmit = handleSubmit(async data => {
    await updateUser({
      isPublic: data.isPublic,
      username: data.username,
      bio: data.bio || undefined,
    })
    reset(data)
  })

  return (
    <form
      className={twMerge('flex w-full flex-col gap-4', className)}
      onSubmit={_onSubmit}
      id={'profile-edit-form'}
    >
      <Input id='email' label='Email' value={profile?.email} disabled />

      <Input
        required
        label={'Název profilu'}
        placeholder={'Mázl'}
        id={'username'}
        register={register}
        name={'username'}
        error={errors['username']}
      />

      <VisibilityToggleGroup
        control={control}
        name={'isPublic'}
        label={
          <>
            <span className={'flex items-center gap-2'}>
              Viditelnost profilu
              <CustomTooltip
                message={
                  'Veřejná viditelnost tvého profilu, Veřejný znamená, že tvůj profil bude viditelný pro všechny. U každého linku si však můžeš zvlášť nastavit, jestli chceš aby byl na tvém profilu dohledatelný nebo ne.'
                }
              />
            </span>
          </>
        }
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextArea
            onChange={onChange}
            value={value as string}
            label={'Bio'}
            placeholder={'....'}
            id={'bio'}
            error={errors['bio']}
            maxCharacters={inputsValidationConfig.bio.maxLength}
            rows={4}
          />
        )}
        name={'bio'}
        control={control}
      />
    </form>
  )
}

export default UserSettingsForm
