import React, { ReactElement, ReactNode } from 'react'
import ToggleGroupItem, { ToggleGroupItemProps } from './ToggleGroupItem.tsx'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

type ToggleGroupProps = {
  onChange?: (value: string | boolean) => void
  selectedValue?: string | null | boolean
  className?: string
  'data-test'?: string
  children?: ReactNode
  label?: string | ReactNode
}
function isToggleGroupItem(element: any): element is ReactElement<ToggleGroupItemProps> {
  return React.isValidElement(element) && element.type === ToggleGroupItem
}
const ToggleGroup = ({
  children,
  selectedValue,
  onChange,
  className,
  'data-test': dataTest,
  label,
}: ToggleGroupProps) => {
  const enhanceChildren = React.Children.map(children, child => {
    if (isToggleGroupItem(child)) {
      return React.cloneElement<ToggleGroupItemProps>(child, {
        isActive: child.props.value === selectedValue,
        onClick: () => onChange && onChange(child.props.value),
      })
    }
    return null
  })

  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      {label && <label className={twMerge('flex items-center gap-2 text-sm')}>{label}</label>}
      <div className={classNames('flex gap-4')} data-test={dataTest}>
        {enhanceChildren}
      </div>
    </div>
  )
}

export default ToggleGroup
