import AxiosErrorHandler from '@/lib/axios/AxiosErrorHandler.tsx'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from '@/lib/queryClient.ts'
import { AuthProvider } from '@/context/authContext.tsx'
import { Toaster } from 'react-hot-toast'
import CustomRouter from '@/constants/routes.tsx'
import PersistLoginLayout from '@/components/layout/PersistLoginLayout/PersistLoginLayout.tsx'

function App() {
  return (
    <>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AxiosErrorHandler>
            <PersistLoginLayout>
              <CustomRouter />
            </PersistLoginLayout>
          </AxiosErrorHandler>
        </QueryClientProvider>
      </AuthProvider>
      <Toaster />
    </>
  )
}

export default App
