// React Router DOM
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '@/context/authContext.tsx'
import { ROUTES } from '@/constants/routes.tsx'

const AuthenticatedLayout = () => {
  const { auth } = useAuth()

  const isLoggedIn = !!auth

  if (!isLoggedIn) return <Navigate to={ROUTES.LANDING} />

  return <Outlet />
}

export default AuthenticatedLayout
