// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { formatDateTimeToString } from '@/utils/date.ts'
import { TOrderAccessSettings } from '@/types/orderAccessSettings.ts'
import CustomTooltip from '@/components/ui/CustomTooltip'

type AccessSettingsCardProps = {
  className?: string
  accessSettings: TOrderAccessSettings
  accessibleUntil: Date
}

const AccessSettingsCard = ({ className, accessibleUntil }: AccessSettingsCardProps) => {
  return (
    <div
      className={twMerge(
        'flex justify-between rounded-lg bg-orange-100 p-4 text-orange-600',
        className,
      )}
    >
      <div className={'flex items-center gap-2'}>
        <span>Časový limit</span>
        <CustomTooltip message={'Po uplynutí času nebude možné soubor zobrazit'} />
      </div>
      <span className={'font-medium'}>{formatDateTimeToString(accessibleUntil)}</span>
    </div>
  )
}

export default AccessSettingsCard
