import { useMutation, useQueryClient } from '@tanstack/react-query'
import QUERY_KEYS from '@/constants/queryKeys.ts'
import {
  createPasswordReset,
  getPasswordResetById,
  resetPassword,
} from '@/api/requests/passwordReset.requests.ts'
import { useEnhancedQuery } from '../api'
import toast from 'react-hot-toast'

export const useCreatePasswordReset = () => {
  return useMutation({
    mutationFn: createPasswordReset,
    onError: () => toast.error('Něco se pokazilo, zkus to prosím později'),
  })
}

export const useResetPassword = () => {
  return useMutation({ mutationFn: resetPassword })
}

export const useGetPasswordReset = (id?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.PASSWORD_RESET, id],
      queryFn: () => getPasswordResetById(id as string),
      enabled: !!id,
    }),
    invalidate: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PASSWORD_RESET, id] }),
  }
}
