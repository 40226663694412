import { z } from 'zod'
import config, { inputsValidationConfig } from '@/constants/config.ts'
import { EBusinessType } from '@/types/enum/BusinessType.ts'
import { ECompanyVatStatus } from '@/types/enum/CompanyVatStatus.ts'

export const priceSchema = z
  .number({ required_error: 'Toto pole je povinné', invalid_type_error: 'Toto pole je povinné' })
  .min(config.MIN_PRICE, `Minimální částka je ${config.MIN_PRICE / 100} kč`)
  .max(config.MAX_PRICE, `Maximální částka je ${config.MAX_PRICE / 100} kč`)

export const orderAccessDurationLimitSchema = z
  .number()
  .max(inputsValidationConfig.orderAccessDurationLimit.max)
  .min(inputsValidationConfig.orderAccessDurationLimit.min)
  .nullable()

export const offerNameSchema = z
  .string()
  .min(
    inputsValidationConfig.offerName.minLength,
    `Název musí mít alespoň ${inputsValidationConfig.offerName.minLength} znaků`,
  )
  .max(
    inputsValidationConfig.offerName.maxLength,
    `Název může mít maximálně ${inputsValidationConfig.offerName.maxLength} znaků`,
  )

export const passwordSchema = z.string().min(8, 'Heslo musí mít alespoň 8 znaků')

export const emailSchema = z.string().email('Zadejte platný email')
export const invitationCodeSchema = z.string().optional()

export const firstNameSchema = z
  .string()
  .min(1, 'Jméno je povinné')
  .max(inputsValidationConfig.firstName.maxLength, 'Jméno je příliš dlouhé')

export const bioSchema = z
  .string()
  .max(inputsValidationConfig.bio.maxLength, 'Bio je příliš dlouhé')
  .optional()
  .nullable()

export const captionSchema = z
  .string()
  .max(inputsValidationConfig.caption.maxLength, 'Bio je příliš dlouhé')
  .optional()
  .nullable()

export const usernameSchema = z
  .string()
  .min(1, 'Název profilu je povinný')
  .max(
    inputsValidationConfig.username.maxLength,
    `Název profilu může mít maximálně ${inputsValidationConfig.username.maxLength} znaků`,
  )
  .regex(
    inputsValidationConfig.username.regex,
    'Název profilu nesmí obsahovat speciální znaky ani mezery',
  )

export const lastNameSchema = z
  .string()
  .min(1, 'Příjmení je povinné')
  .max(inputsValidationConfig.lastName.maxLength, 'Příjmení je příliš dlouhé')

export const businessTypeSchema = z.nativeEnum(EBusinessType, {
  required_error: 'Typ podnikání musí být vyplněn',
  invalid_type_error: 'Typ podnikání musí být vyplněn',
})

export const companyVatStatusSchema = z.nativeEnum(ECompanyVatStatus, {
  required_error: 'Plátcovství musí být vyplněno',
  invalid_type_error: 'error',
})

export const companyNameSchema = z
  .string()
  .max(inputsValidationConfig.companyRegistrationId.maxLength, 'Název je příliš dlouhý')

export const companyRegistrationIdSchema = z
  .string()
  .max(inputsValidationConfig.companyRegistrationId.maxLength, 'Ičo je příliš dlouhé')
export const vatIdSchema = z
  .string()
  .max(inputsValidationConfig.vatId.maxLength, 'DIČ je příliš dlouhé')

export const citySchema = z
  .string()
  .max(inputsValidationConfig.city.maxLength, 'Název města je příliš dlouhý')

export const streetNumberSchema = z
  .string()
  .max(inputsValidationConfig.streetNumber.maxLength, 'Číslo popisné je příliš dlouhé')
  .regex(inputsValidationConfig.streetNumber.regex, 'Špatný formát čísla popisného')

export const streetSchema = z
  .string()
  .max(inputsValidationConfig.street.maxLength, 'Název ulice je moc dlouhý')

export const phoneNumberSchema = z
  .string()
  .max(inputsValidationConfig.phoneNumber.maxLength, 'Telefonní číslo je příliš dlouhé')
  .regex(inputsValidationConfig.phoneNumber.regex, 'Telefonní číslo je neplatné')

export const postalCodeSchema = z
  .string()
  .max(inputsValidationConfig.postalCode.maxLength, 'PSČ je příliš dlouhé')
  .regex(inputsValidationConfig.postalCode.regex, 'neplatné PSČ')
