// Tailwind Merge
import ModalHeading from '@/components/atom/ModalHeading'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { twMerge } from 'tailwind-merge'
import { useCreatePasswordReset } from '@/api/queries/passwordReset.queries.ts'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import { emailSchema } from '@/utils/zod.ts'

type PasswordResetFormProps = {
  className?: string
  onSuccessfulSubmit?: () => void
}

const passwordResetRequestSchema = z.object({
  email: emailSchema,
})

export type TPasswordResetRequestSchema = z.infer<typeof passwordResetRequestSchema>

const PasswordResetRequestForm = ({ className, onSuccessfulSubmit }: PasswordResetFormProps) => {
  const { mutateAsync: createPasswordReset } = useCreatePasswordReset()
  const {
    register,
    formState: { isValid, isSubmitSuccessful, isSubmitting },
    handleSubmit,
  } = useForm<TPasswordResetRequestSchema>({
    resolver: zodResolver(passwordResetRequestSchema),
    mode: 'onChange',
  })

  const _onSubmit = handleSubmit(async data => {
    await createPasswordReset(data)
  })

  if (isSubmitSuccessful) {
    return (
      <div className={'flex flex-col gap-8 px-10'}>
        <ModalHeading
          heading={'Email pro obnovu hesla odeslán📩'}
          subHeading={
            'Pokud k zadané schránce exstije přidružený účet, odeslali jsme ti potvrzovací email. zkontroluj si schránku'
          }
        />
        <Button
          label={'Hotovo!🥳'}
          className={'w-full'}
          onClick={() => onSuccessfulSubmit && onSuccessfulSubmit()}
        />
      </div>
    )
  }

  return (
    <div className={twMerge('flex flex-col gap-2 px-10', className)}>
      <ModalHeading heading={'Obnova hesla🔒'} subHeading={'Níže vyplň svůj email'} />
      <form className={'flex flex-col gap-2'} onSubmit={_onSubmit}>
        <Input id={'email'} label={'Email'} type={'email'} register={register} name={'email'} />
        <Button
          label={'Obnovit heslo'}
          className={'w-full'}
          disabled={!isValid}
          isLoading={isSubmitting}
        />
      </form>
    </div>
  )
}

export default PasswordResetRequestForm
