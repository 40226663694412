import PageContainer from '@/components/ui/PageContainer'
import PageHeading from '@/components/atom/PageHeading'
import Button from '@/components/ui/Button'
import { ROUTES } from '@/constants/routes.tsx'

const BusinessRequiredPage = () => {
  return (
    <PageContainer>
      <PageHeading backButton />
      <div
        className={'flex flex-1 flex-col items-center gap-2 text-center'}
        style={{ paddingTop: '5%' }}
      >
        <img src={'/img/emoji/emoji-4.png'} className={'mb-3 w-1/2 max-w-48 '} alt={'emoji'} />
        <h1 className={'text-xl font-bold text-primary-600'}>Je zapotřebí doplnit údaje profilu</h1>
        <p className={'font-medium text-gray-500'}>
          Pro pokračování musíš vyplnit fakturační údaje v detailu profilu <br />
          <b>(Profil → ⚙️ → Nastavení fakturačních údajů )</b>
        </p>
        <Button
          className={'mt-3 w-full'}
          label={'Vyplnit fakturační údaje📝'}
          href={ROUTES.BUSINESS_SETTINGS}
        />
      </div>
    </PageContainer>
  )
}

export default BusinessRequiredPage
