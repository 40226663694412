// Tailwind Merge

import { formatDateToString } from '@/utils/date.ts'
import { twMerge } from 'tailwind-merge'
import { TOffer } from '@/types/offer.ts'
import FileThumbnail from '@/components/molecule/FilePreviewThumbnail'
import Button from '@/components/ui/Button'
import { ROUTES } from '@/constants/routes.tsx'
import Caption from '@/components/ui/Caption'

type OffersGalleryItemProps = {
  className?: string
  offer: TOffer
  type: 'public' | 'owner'
}

const OffersFeedItem = ({ className, offer, type }: OffersGalleryItemProps) => {
  return (
    <div
      className={twMerge(
        'mb-6 flex w-full flex-col items-center gap-3 overflow-hidden  border-b pb-3',
        className,
      )}
    >
      <FileThumbnail
        file={offer.files[0]}
        type={'locked'}
        showLock
        price={offer.price}
        centerContent={
          <Button
            label={type === 'public' ? 'Odemknout 🥳' : 'Přehled linku📙'}
            href={
              type === 'public'
                ? ROUTES.OFFER_DETAIL_PUBLIC + offer.id
                : `${ROUTES.OFFERS}/${offer.id}`
            }
          />
        }
      />
      <div className={'w-full'}>
        <h2 className={'text-xl font-bold'}>{offer.name}</h2>
        <Caption caption={offer.caption} />
        <div className={'mt-2 font-semibold text-gray-400'}>
          {formatDateToString(offer.createdAt)}
        </div>
      </div>
    </div>
  )
}

export default OffersFeedItem
