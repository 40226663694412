// Page components

// Layouts
import MainLayout from '@/components/layout/MainLayout'

// Types and enums

// Route components generator
import LockFilePage from '@/components/page/LockFilePage'
import OfferPrivateDetailPage from '@/components/page/OfferPrivateDetailPage'
import OfferPublicDetailPage from '@/components/page/OfferPublicDetailPage'
import AnonymousLayout from '@/components/layout/AnonymousLayout'
import ClearLayout from '@/components/layout/ClearLayout'
import OffersPage from '@/components/page/OffersPage'
import OrderDetailCustomerPage from '@/components/page/OrderDetailCustomerPage'
import OrderManagementPage from '@/components/page/OrderManagementPage'
import OfferOrdersPage from '@/components/page/OfferOrdersPage'
import LandingPage from '@/components/page/LandingPage'
import RegistrationPage from '@/components/page/RegistrationPage'
import PasswordResetPage from '@/components/page/PasswordResetPage'
import OfferSettingsPage from '@/components/page/OfferSettingsPage'
import AllOffersPage from '@/components/page/AllOffersPage'
import OfferSharePage from '@/components/page/OfferSharePage'
import BusinessAccountSettingsPage from '@/components/page/BusinessAccountSettingsPage/BusinessAccountSettingsPage.tsx'
import StripeOnboardingPage from '@/components/page/StripeOnboardingPage'
import BusinessRequiredPage from '@/components/page/BusinessRequiredPage'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'
import { useMemo } from 'react'
import { useAuth } from '@/context/authContext.tsx'
import AuthenticatedLayout from '@/lib/router/AuthenticatedLayout.tsx'

import UserDetailPage from '@/components/page/UserDetailPage'
import UserSettingsPage from '@/components/page/UserSettingsPage'
import ScrollToTopLayout from '@/components/layout/AnonymousLayout/ScrollToTopLayout/ScrollToTopLayout.tsx'

export const ROUTES = Object.freeze({
  DASHBOARD: '/',
  OFFER_SHARE: '/offer/share',
  OFFER_DETAIL_PUBLIC: '/',
  ORDER_DETAIL: '/order',
  ORDER_MANAGEMENT_DETAIL: '/order-management',
  LOGIN: '/login',
  SETTINGS: '/my-profile/settings',
  BUSINESS_SETTINGS: '/my-profile/settings/business',
  STRIPE_ONBOARDING_PAGE: '/my-profile/settings/business/stripe-onboarding',
  OFFERS: '/offers',
  ALL_OFFERS: '/offers/my',
  OFFER_ORDERS: '/offers/:id/orders',
  OFFER_EDIT: '/offers/:id/settings',
  LANDING: '/',
  REGISTRATION: '/registration',
  PASSWORD_RESET: '/password-reset',
  BUSINESS_REQUIRED: '/business-required',
  USER_DETAIL: '/user',
})

const CustomRouter = () => {
  const { isAuthenticated } = useAuth()

  const router = useMemo(() => {
    const routes: RouteObject[] = []

    // both authenticated/unauthenticated
    routes.push({
      element: <ScrollToTopLayout />,
      children: [
        { element: <Navigate to={ROUTES.LANDING} />, path: '*' },
        {
          element: <AnonymousLayout hideLogo />,
          children: [
            {
              path: `${ROUTES.DASHBOARD}/:id`,
              element: <OfferPublicDetailPage />,
            },
          ],
        },
      ],
    })

    if (isAuthenticated) {
      routes.push({
        element: <ScrollToTopLayout />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                element: <AuthenticatedLayout />,
                children: [
                  {
                    path: ROUTES.DASHBOARD,
                    element: <LockFilePage />,
                  },
                  {
                    path: `${ROUTES.OFFER_SHARE}/:id`,
                    element: <OfferSharePage />,
                  },
                  {
                    path: `${ROUTES.USER_DETAIL}/:username`,
                    element: <UserDetailPage />,
                  },
                  {
                    path: `${ROUTES.SETTINGS}`,
                    element: <UserSettingsPage />,
                  },
                  {
                    path: `${ROUTES.BUSINESS_SETTINGS}`,
                    element: <BusinessAccountSettingsPage />,
                  },
                  {
                    path: `${ROUTES.STRIPE_ONBOARDING_PAGE}`,
                    element: <StripeOnboardingPage />,
                  },
                  {
                    path: `${ROUTES.OFFERS}`,
                    element: <OffersPage />,
                  },
                  {
                    path: `${ROUTES.OFFERS}/:id`,
                    element: <OfferPrivateDetailPage />,
                  },
                  {
                    path: `${ROUTES.ORDER_MANAGEMENT_DETAIL}/:id`,
                    element: <OrderManagementPage />,
                  },
                  {
                    path: ROUTES.OFFER_ORDERS,
                    element: <OfferOrdersPage />,
                  },
                  {
                    path: `${ROUTES.OFFER_EDIT}`,
                    element: <OfferSettingsPage />,
                  },
                  {
                    path: `${ROUTES.ALL_OFFERS}/:state`,
                    element: <AllOffersPage />,
                  },
                  {
                    path: `${ROUTES.BUSINESS_REQUIRED}`,
                    element: <BusinessRequiredPage />,
                  },
                ],
              },
            ],
          },
        ],
      })
    } else {
      routes.push({
        element: <ScrollToTopLayout />,
        children: [
          {
            element: <AnonymousLayout />,
            children: [
              {
                path: `${ROUTES.ORDER_DETAIL}/:id`,
                element: <OrderDetailCustomerPage />,
              },
              {
                path: `${ROUTES.REGISTRATION}/:id`,
                element: <RegistrationPage />,
              },
              {
                path: `${ROUTES.PASSWORD_RESET}/:id`,
                element: <PasswordResetPage />,
              },
              {
                path: `${ROUTES.USER_DETAIL}/:username`,
                element: <UserDetailPage />,
              },
            ],
          },
          {
            element: <ClearLayout />,
            children: [{ element: <LandingPage />, path: ROUTES.LANDING }],
          },
        ],
      })
    }

    routes.push()

    return createBrowserRouter(routes, {
      basename: '/',
    })
  }, [isAuthenticated])

  return <RouterProvider router={router} />
}

export default CustomRouter
