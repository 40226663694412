// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { ReactNode } from 'react'

type TAboutListItem = {
  label: ReactNode
  value: ReactNode
  boldValue?: boolean
}

type AboutListProps = {
  className?: string
  heading?: string
  items: TAboutListItem[]
}

const AboutList = ({ className, items, heading }: AboutListProps) => {
  const _renderItem = (item: TAboutListItem) => {
    const { label, value, boldValue } = item
    return (
      <li className={'flex justify-between'} key={String(label)}>
        <span>{label}</span>
        <span className={twMerge(boldValue && 'font-bold')}>{value}</span>
      </li>
    )
  }

  return (
    <ul
      className={twMerge(
        'flex w-full flex-col gap-2 rounded-lg bg-surface-100 p-4 text-surface-800',
        className,
      )}
    >
      {heading && <span className={'text-lg font-semibold'}>{heading}</span>}
      {items.map(item => _renderItem(item))}
    </ul>
  )
}

export default AboutList
