// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { CSSProperties, useState } from 'react'
import PriceInput from '@/components/atom/PriceInput'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import BottomPopover from '@/components/molecule/BottomPopover'
import OfferSettingsFormPartition from '@/components/form/CreateOfferForm/components/OfferSettingsFormPartition'
import Button from '@/components/ui/Button'
import {
  captionSchema,
  offerNameSchema,
  orderAccessDurationLimitSchema,
  priceSchema,
} from '@/utils/zod.ts'

const createOfferSchema = z.object({
  price: priceSchema,
  orderAccessDurationLimit: orderAccessDurationLimitSchema,
  name: offerNameSchema,
  isPublic: z.boolean(),
  caption: captionSchema,
})

export type TCreateOfferSchema = z.infer<typeof createOfferSchema>

type CreateOfferFormProps = {
  className?: string
  style?: CSSProperties
  onSubmit?: (data: TCreateOfferSchema) => Promise<void>
  disabled?: boolean
}

const CreateOfferForm = ({ className, style, onSubmit, disabled }: CreateOfferFormProps) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const methods = useForm<TCreateOfferSchema>({
    resolver: zodResolver(createOfferSchema),
    defaultValues: {
      orderAccessDurationLimit: null,
      isPublic: false,
    },
  })

  const {
    handleSubmit,
    control,
    trigger,
    formState: { isSubmitting, errors },
  } = methods

  const _onSubmit = handleSubmit(data => {
    onSubmit && onSubmit({ ...data, price: data.price })
  })

  const _startSettings = async () => {
    const result = await trigger(['price'])
    if (!result) return
    setIsSettingsOpen(true)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={twMerge(
            'flex w-full flex-1 flex-col items-center justify-between pb-3',
            className,
          )}
          style={style}
          onSubmit={_onSubmit}
        >
          <div className={'flex h-full flex-col items-center justify-center gap-1'}>
            <Controller
              render={({ field: { onChange } }) => (
                <PriceInput
                  onChange={onChange}
                  disabled={disabled}
                  error={errors['price']?.message}
                />
              )}
              name={'price'}
              control={control}
            />
          </div>
          <Button
            label={'Vytvořit odkaz🥳'}
            size={'large'}
            className={'w-full'}
            type='button'
            isLoading={isSubmitting}
            onClick={_startSettings}
            disabled={disabled}
          />
          <BottomPopover
            title={'Nastavení'}
            isOpen={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
            className={className}
          >
            <OfferSettingsFormPartition onCancel={() => setIsSettingsOpen(false)} />
          </BottomPopover>
        </form>
      </FormProvider>
    </>
  )
}

export default CreateOfferForm
