import PageHeading from '@/components/atom/PageHeading'
import OrderStateBox from '@/components/atom/OrderStateBox'
import { useParams } from 'react-router-dom'
import { useGetOrderPrivateById } from '@/api/queries/order.queries.ts'
import AboutList from '@/components/molecule/AboutList'
import { formatDateToString } from '@/utils/date.ts'
import PageContainer from '@/components/ui/PageContainer'
import { twMerge } from 'tailwind-merge'
import { orderPaymentStatusDictionary } from '@/constants/orderPaymentStatusDictionary.ts'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus.tsx'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import { ROUTES } from '@/constants/routes.tsx'
import ScreenLoading from '@/components/atom/ScreenLoading'

const OrderManagementPage = () => {
  const { id } = useParams()
  const { data, isLoading, isNotFound } = useGetOrderPrivateById(id)

  if (isLoading) {
    return <ScreenLoading />
  }

  if (isNotFound) {
    return (
      <InfoMessageContent
        heading='Objednávka nenalezena.'
        message='Objednávku se nepodařilo najít, zkontroluj si správnost odkazu.'
        actionButtonLabel='Zpět ↩️'
        actionButtonHref={ROUTES.OFFERS}
      />
    )
  }

  if (!data?.data) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.OFFERS} />
  }

  const order = data.data
  const { paymentStatus, price, email, createdAt, shortId, paidAt, fullName } = order

  const _renderPriceContent = () => {
    switch (paymentStatus) {
      case EOrderPaymentStatus.SUCCEED:
        return <span className={' -mt-2 text-lg font-semibold text-primary-500'}>+ {price} Kč</span>
      case EOrderPaymentStatus.PENDING:
        return <span className={' -mt-2 text-lg font-semibold  text-gray-400'}>{price} Kč</span>
      case EOrderPaymentStatus.PROCESSING:
        return <span className={' -mt-2 text-lg font-semibold  text-gray-400'}>{price} Kč</span>
      default:
        return null
    }
  }

  return (
    <PageContainer>
      <PageHeading title={'Detail objednávky'} backButton />
      <div className={'flex flex-col items-center justify-center gap-2'}>
        <OrderStateBox state={paymentStatus} size={'large'} />
        <span
          className={twMerge(
            'text-xl font-semibold',
            paymentStatus === EOrderPaymentStatus.SUCCEED && 'text-primary-500',
            paymentStatus === EOrderPaymentStatus.PENDING && 'text-yellow-600',
            paymentStatus === EOrderPaymentStatus.PROCESSING && 'text-yellow-600',
          )}
        >
          {orderPaymentStatusDictionary[paymentStatus]}
        </span>
        {_renderPriceContent()}
      </div>
      <div className={'flex flex-col items-center justify-center'}>
        <span className={'text-xl font-semibold'}>{fullName || '--'}</span>
        <span className={'semibold'}>{email}</span>
      </div>
      <AboutList
        className={'mt-6'}
        items={[
          {
            label: `Č. objednávky`,
            value: shortId,
          },
          {
            value: `${price} Kč`,
            label: 'Cena',
          },
          {
            label: `Datum objednávky`,
            value: formatDateToString(createdAt),
          },
          {
            label: `Datum platby`,
            value: paidAt ? formatDateToString(paidAt) : '--',
          },
        ]}
      />
    </PageContainer>
  )
}

export default OrderManagementPage
