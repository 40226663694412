// Tailwind Merge
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import ToggleGroup from '@/components/form/ButtonGroup/ToggleGroup.tsx'
import ToggleGroupItem from '@/components/form/ButtonGroup/ToggleGroupItem.tsx'
import { IconEye, IconEyeClosed } from '@tabler/icons-react'
import { ReactNode } from 'react'

type VisibilityToggleGroup<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  label?: ReactNode
}

function VisibilityToggleGroup<T extends FieldValues>({
  control,
  name,
  label,
}: VisibilityToggleGroup<T>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <ToggleGroup label={label} onChange={onChange} selectedValue={value}>
            <ToggleGroupItem label={'Neveřejný'} value={false} icon={IconEyeClosed} />
            <ToggleGroupItem label={'Veřejný'} value={true} icon={IconEye} />
          </ToggleGroup>
        )
      }}
      name={name}
    />
  )
}

export default VisibilityToggleGroup
