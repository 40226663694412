import { useMutation } from '@tanstack/react-query'
import { AxiosError, HttpStatusCode } from 'axios'
import toast from 'react-hot-toast'
import { createPreRegistrationRequest } from '@/api/requests/preRegistration.requests.ts'

export const useCreatePreRegistration = () => {
  return useMutation({
    mutationFn: createPreRegistrationRequest,
    onError: e => {
      if (e instanceof AxiosError && e.response?.status === HttpStatusCode.Conflict) {
        toast.error('Email již byl použit')
        throw e
      }

      toast.error('Něco se pokazilo, zkus to prosím později')
      throw e
    },
  })
}
