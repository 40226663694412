// Tailwind Merge
import TimeAccessSettingInput from '@/components/form/TimeAccessSettingInput'
import { Controller, useFormContext } from 'react-hook-form'
import { TCreateOfferSchema } from '@/components/form/CreateOfferForm/CreateOfferForm.tsx'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import TextArea from '@/components/ui/TextArea'
import CustomTooltip from '@/components/ui/CustomTooltip'
import { inputsValidationConfig } from '@/constants/config.ts'
import VisibilityToggleGroup from '@/components/form/VisibilityToggleGroup'

type OfferSettingsFormProps = {
  onCancel?: () => void
}

const OfferSettingsFormPartition = ({ onCancel }: OfferSettingsFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<TCreateOfferSchema>()

  return (
    <div className={'flex flex-col gap-4'}>
      <Input
        id={'name'}
        label={'Název linku'}
        register={register}
        name={'name'}
        error={errors['name']}
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextArea
            id={'caption'}
            label={'Popis'}
            maxCharacters={inputsValidationConfig.caption.maxLength}
            onChange={e => onChange(e.target.value)}
            value={value}
          />
        )}
        name={'caption'}
      />
      <VisibilityToggleGroup
        control={control}
        name={'isPublic'}
        label={
          <>
            Viditelnost linku
            <CustomTooltip message={'Určuje, zda li bude link vidět na tvém profilu'} />
          </>
        }
      />
      <Controller
        render={({ field: { onChange, value } }) => (
          <TimeAccessSettingInput onDurationChange={onChange} duration={value} />
        )}
        name={'orderAccessDurationLimit'}
        control={control}
      />
      <div className={'flex justify-end gap-3'}>
        <Button
          label={'Zrušit'}
          variant={'plain'}
          className={'text-red-600'}
          type={'button'}
          onClick={() => onCancel && onCancel()}
        />
        <Button label={'Vytvořit odkaz'} isLoading={isSubmitting} type={'submit'} />
      </div>
    </div>
  )
}

export default OfferSettingsFormPartition
