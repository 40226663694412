// Tailwind Merge
import { twMerge } from 'tailwind-merge'

type BadgeProps = {
  className?: string
  variant: 'primary' | 'warning' | 'disabled'
  children?: React.ReactNode
  size?: 'sm' | 'md'
}

const Badge = ({ className, variant, children, size }: BadgeProps) => {
  return (
    <span
      className={twMerge(
        'flex w-fit items-center gap-1 rounded-md p-1 text-sm font-semibold',
        variant === 'primary' && 'bg-primary-200 text-primary-600',
        variant === 'disabled' && 'bg-gray-200 text-gray-600',
        variant === 'warning' && 'bg-orange-100  text-orange-600',
        className,
        size === 'sm' && 'text-xs',
      )}
    >
      {children}
    </span>
  )
}

export default Badge
