import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  activateOffer,
  createOffer,
  deactivateOffer,
  getMyOffers,
  getPrivateOfferDetailById,
  getPublicOfferDetailById,
  getUsersOffers,
  updateOffer,
} from '@/api/requests/offer.requests.ts'
import QUERY_KEYS from '@/constants/queryKeys.ts'
import { useCustomInfiniteQuery, useEnhancedQuery } from '@/api/api.ts'
import { EOfferState } from '@/types/enum/offerState.ts'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'
import queryClient from '@/lib/queryClient'
import toast from 'react-hot-toast'

export const useCreateOffer = () => {
  return useMutation({
    mutationFn: createOffer,
    onSuccess: data => {
      if (data)
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.MY_OFFERS],
        })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS_OFFERS],
      })
    },
    onError: () => toast.error('Něco se pokazilo, zkus to prosím později'),
  })
}

export const useUpdateOffer = () => {
  return useMutation({
    mutationFn: updateOffer,
    onSuccess: data => {
      toast.success('Změny byly uloženy')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_PRIVATE_DETAIL, data.data.id] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_OFFERS] })
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS_OFFERS],
      })
    },
    onError: () => {
      toast.error('Chyba při ukládání změn, zkus to prosím později')
    },
  })
}
export const useDeactivateOffer = () => {
  return useMutation({
    mutationFn: deactivateOffer,
    onSuccess: (_, id) => {
      toast.success('Link byla deaktivován')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_PRIVATE_DETAIL, id] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_OFFERS] })
    },
    onError: () => toast.error('Chyba při deaktivaci linku, zkus to prosím později'),
  })
}

export const useActivateOffer = () => {
  return useMutation({
    mutationFn: activateOffer,
    onSuccess: (_, id) => {
      toast.success('Link byl aktivován')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_PRIVATE_DETAIL, id] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_OFFERS] })
    },
    onError: () => toast.error('Chyba při aktivaci linku, zkus to prosím později'),
  })
}
export const useGetOfferPrivateById = (offerId?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.OFFER_PRIVATE_DETAIL, offerId],
      queryFn: () => getPrivateOfferDetailById(offerId as string),
      enabled: !!offerId,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_PRIVATE_DETAIL, offerId] }),
  }
}

export const useGetOfferPublicById = (offerId?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.OFFER_PUBLIC_DETAIL, offerId],
      queryFn: () => getPublicOfferDetailById(offerId as string),
      enabled: !!offerId,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_PUBLIC_DETAIL, offerId] }),
  }
}

export const useGetMyOffers = (
  offerState?: EOfferState,
  limit?: number,
  uploadStatus?: EOfferUploadStatus,
) => {
  const queryClient = useQueryClient()

  return {
    ...useCustomInfiniteQuery({
      limit,
      queryKey: [QUERY_KEYS.MY_OFFERS, offerState, uploadStatus],
      queryFn: getMyOffers,
      fnParams: { params: { state: offerState, uploadStatus } },
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_OFFERS, offerState, uploadStatus] }),
    reset: () =>
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.MY_OFFERS, offerState, uploadStatus] }),
  }
}

export const useGetUsersOffers = (userId?: string, limit?: number) => {
  const queryClient = useQueryClient()

  return {
    ...useCustomInfiniteQuery({
      limit,
      queryKey: [QUERY_KEYS.USERS_OFFERS, userId],
      queryFn: getUsersOffers,
      fnParams: { userId },
      enabled: !!userId,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USERS_OFFERS, userId] }),
    reset: () => queryClient.resetQueries({ queryKey: [QUERY_KEYS.USERS_OFFERS, userId] }),
  }
}
