import { useGetOfferPublicById } from '@/api/queries/offer.queries.ts'
import { useParams } from 'react-router-dom'
import PayCard from '@/components/molecule/PayCard'
import FileThumbnail from '@/components/molecule/FilePreviewThumbnail'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'
import PageContainer from '@/components/ui/PageContainer'
import Caption from '@/components/ui/Caption'
import OfferUserAvatar from '@/components/atom/OfferUserAvatar'
import { useGetUserById } from '@/api/queries/user.queries.ts'
import Logo from '@/components/atom/Logo'
import SEO from '@/components/atom/Seo'

const OfferPublicDetailPage = () => {
  const { id } = useParams()
  const {
    data: offerData,
    isLoading: isOfferLoading,
    isNotFound,
    isError,
  } = useGetOfferPublicById(id)
  const { data: userData, isLoading: isUserLoading } = useGetUserById(offerData?.data?.userId)

  if (isUserLoading || isOfferLoading) {
    return <ScreenLoading />
  }

  if (isNotFound) {
    return (
      <InfoMessageContent
        heading='Link se nepodařilo najít. 😢'
        message='Link se nepodařilo najít. Buďto neexistuje, nebo byl deaktivován'
      />
    )
  }

  if (!offerData?.data || isError) {
    return <GlobalErrorInfoMessageContent />
  }

  const offer = offerData.data
  const { price, files, caption, orderAccessSettings } = offer
  const file = files[0]

  return (
    <>
      <SEO
        title={offer.name}
        description={offer.caption}
        image={
          'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR2v8jGQFEHwDE0bEIm2Sofs-0n5RUWyiNtY_JQw46IozVB-YPU'
        }
      />
      <PageContainer className={'-mb-24 flex h-screen flex-col  items-center gap-12'}>
        <div className={'flex h-[5%] items-center justify-center'}>
          <Logo size={'md'} />
        </div>

        <div className={'mb-4 flex w-full flex-col gap-6'} style={{ maxWidth: 450 }}>
          {userData?.data && <OfferUserAvatar user={userData.data} />}

          <FileThumbnail
            file={file}
            showLock
            type={'locked'}
            className={'w-full overflow-hidden'}
            offerLimit={orderAccessSettings.orderAccessDurationLimit}
            showDuration={true}
          />
          <div className={'flex flex-col gap-2'}>
            <h1 className={'ml-1 w-full  text-center text-2xl font-semibold'}>{offer.name}</h1>
            <Caption caption={caption} className={'text-center'} />
          </div>
        </div>
        <div className={'fixed bottom-8 left-0 right-0 m-auto px-4'} style={{ maxWidth: '600px' }}>
          <PayCard price={price} offerId={offer.id} />
        </div>
      </PageContainer>
    </>
  )
}

export default OfferPublicDetailPage
