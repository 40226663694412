const config = Object.freeze({
  MAX_PRICE: import.meta.env.VITE_APP_MAX_PRICE || 99999900,
  MIN_PRICE: import.meta.env.VITE_APP_MIN_PRICE || 2000,
  API_URL: import.meta.env.VITE_APP_API_URL,
  CLIENT_URL: import.meta.env.VITE_APP_CLIENT_URL,
  NODE_ENV: import.meta.env.VITE_APP_NODE_ENV,
})

export const inputsValidationConfig = {
  offerName: {
    maxLength: 220,
    minLength: 5,
  },
  streetNumber: {
    regex: /^[A-Za-z0-9]*(\/[A-Za-z0-9]*)?$/,
    maxLength: 20,
    minLength: 1,
  },
  phoneNumber: {
    maxLength: 20,
    regex: /^\+?[0-9 ]{9,19}$/,
    minLength: 1,
  },
  postalCode: {
    regex: /^(?:\d\s?){5}$/,
    maxLength: 7,
    minLength: 1,
  },
  street: {
    maxLength: 255,
    minLength: 1,
  },
  city: {
    maxLength: 150,
    minLength: 1,
  },
  vatId: {
    maxLength: 40,
    minLength: 1,
  },
  companyRegistrationId: {
    maxLength: 40,
    minLength: 1,
  },
  companyName: {
    maxLength: 255,
    minLength: 1,
  },
  lastName: {
    maxLength: 255,
    minLength: 1,
  },
  firstName: {
    maxLength: 255,
    minLength: 1,
  },
  username: {
    regex: /^[a-zA-Z0-9_-]*$/,
    maxLength: 30,
  },
  password: {
    minLength: 8,
  },
  bio: {
    maxLength: 150,
  },
  orderAccessDurationLimit: {
    max: 31536000,
    min: 900,
  },
  caption: {
    minLength: 0,
    maxLength: 2200,
  },
}

export default config
