// React Router DOM
import { Outlet } from 'react-router-dom'
import BottomNavbar from '@/components/organism/BottomNavbar'
import { twMerge } from 'tailwind-merge'

//components

const MainLayout = () => {
  return (
    <>
      <body
        className={twMerge('flex h-screen w-full  justify-center  ')}
        style={{ maxHeight: '100svh' }}
      >
        <div className={'flex  w-full flex-col  justify-between '} style={{ maxWidth: '600px' }}>
          <div className={'no-scrollbar relative flex-1 overflow-scroll'} id={'view-container'}>
            <Outlet />
          </div>
          <BottomNavbar />
        </div>
      </body>
    </>
  )
}

export default MainLayout
