// Tailwind Merge
import LinkCopyInput from '@/components/atom/LinkCopyInput'
import { twMerge } from 'tailwind-merge'
import { TOffer } from '@/types/offer.ts'
import config from '@/constants/config'

type OfferShareActionsProps = {
  className?: string
  offer: TOffer
  style?: React.CSSProperties
}

const OfferShareActions = ({ className, offer, style }: OfferShareActionsProps) => {
  return (
    <div className={twMerge('flex flex-col items-center gap-1', className)} style={style}>
      <LinkCopyInput url={`${config.CLIENT_URL}/${offer.id}`} />
    </div>
  )
}

export default OfferShareActions
