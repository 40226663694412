// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconCopy } from '@tabler/icons-react'
import Button from '@/components/ui/Button'

type LinkCopyInputProps = {
  className?: string
  url?: string
}

const LinkCopyInput = ({ className, url }: LinkCopyInputProps) => {
  return (
    <div
      className={twMerge(
        ' flex max-h-full w-full  items-center justify-between gap-2 overflow-scroll text-nowrap rounded-2xl bg-surface-100 p-3',
        className,
      )}
    >
      <span className={'no-scrollbar overflow-scroll'}>{url}</span>
      <Button
        onClick={() => url && navigator.clipboard.writeText(url)}
        label={'Kopírovat'}
        size={'extra-small'}
        className={'bg-white text-black'}
        icon={IconCopy}
      />
    </div>
  )
}

export default LinkCopyInput
