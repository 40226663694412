import PageHeading from '@/components/atom/PageHeading'
import { useLogout } from '@/api/auth.ts'
import toast from 'react-hot-toast'
import Button from '@/components/ui/Button'
import PageContainer from '@/components/ui/PageContainer'
import { useMemo, useState } from 'react'
import { IconWallet } from '@tabler/icons-react'
import { ROUTES } from '@/constants/routes.tsx'
import { useGetMyUser } from '@/api/queries/user.queries.ts'
import ScreenLoading from '@/components/atom/ScreenLoading'
import UserSettingsForm from '@/components/form/UserSettingsForm'

const UserSettingsPage = () => {
  const { logout } = useLogout()
  const [isFormEdited, setIsFormEdited] = useState(false)
  const { data: myUserData, isLoading } = useGetMyUser()
  const businessSettingsUrl = useMemo(() => {
    if (myUserData?.data.businessProfile) return ROUTES.STRIPE_ONBOARDING_PAGE
    return ROUTES.BUSINESS_SETTINGS
  }, [myUserData])

  const _onLogout = async () => {
    try {
      await logout()
    } catch (e) {
      toast.error('Nepodařilo se odhlásit')
    }
  }

  if (isLoading) return <ScreenLoading />

  return (
    <PageContainer>
      <PageHeading
        title={'Nastavení🔧'}
        backButton
        backlink={`${ROUTES.USER_DETAIL}/${myUserData?.data?.id}`}
        rightContent={
          <Button label={'Uložit'} disabled={!isFormEdited} form={'profile-edit-form'} />
        }
      />
      <UserSettingsForm setIsFormEdited={setIsFormEdited} profile={myUserData?.data} />
      <Button
        label={'Nastavení fakturačních údajů'}
        className={'w-full'}
        variant={'neutral'}
        href={businessSettingsUrl}
        type={'submit'}
        icon={IconWallet}
      />
      <Button
        label={'Odhlásit se'}
        className={'w-full'}
        variant={'neutral'}
        onClick={_onLogout}
        type={'submit'}
      />
    </PageContainer>
  )
}

export default UserSettingsPage
