// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { forwardRef, ReactNode } from 'react'

type PageContainerProps = {
  className?: string
  children: ReactNode
}

const PageContainer = forwardRef<HTMLDivElement, PageContainerProps>(
  ({ className, children }, ref) => {
    return (
      <div ref={ref} className={twMerge('flex h-full flex-col  gap-6 px-6 pt-6', className)}>
        {children}
      </div>
    )
  },
)

export default PageContainer
