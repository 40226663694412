// Tailwind Merge
import Modal from '@/components/modal/Modal'
import { TOffer } from '@/types/offer.ts'
import OfferShareActions from '@/components/atom/OfferShareActions'
import { twMerge } from 'tailwind-merge'

type OfferShareModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
  offer: TOffer
}

const OfferShareModal = ({ className, onClose, isOpened, offer }: OfferShareModalProps) => {
  return (
    <Modal onClose={onClose} isOpened={isOpened} className={twMerge(className)}>
      <OfferShareActions offer={offer} />
    </Modal>
  )
}

export default OfferShareModal
