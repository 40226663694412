import PageContainer from '@/components/ui/PageContainer'
import PageHeading from '@/components/atom/PageHeading'
import InfiniteScrollList from '@/components/molecule/InfiniteScrollList'
import { useGetMyOffers } from '@/api/queries/offer.queries.ts'
import OfferPrivateListItem from '@/components/molecule/OfferPrivateListItem'
import { useParams } from 'react-router-dom'
import { EOfferState } from '@/types/enum/offerState.ts'
import { useMemo } from 'react'

const AllOffersPage = () => {
  const params = useParams()
  const state = params.state as EOfferState | undefined
  const { data, fetchNextPage, hasNextPage, reset, isPending } = useGetMyOffers(state)

  const title = useMemo(() => {
    if (state === EOfferState.DEACTIVATED) return 'Deaktivované linky'

    if (state === EOfferState.ACTIVE) return 'Aktivní linky'

    return 'Linky'
  }, [state])

  return (
    <>
      <PageContainer>
        <PageHeading backButton title={title} />
        <InfiniteScrollList
          dataLength={data?.pages.reduce((acc, order) => acc + order.data.length, 0)}
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          refreshFunction={() => reset()}
          isLoading={isPending}
        >
          {data?.pages?.map(page =>
            page.data.map(offer => <OfferPrivateListItem key={offer.id} offer={offer} />),
          )}
        </InfiniteScrollList>
      </PageContainer>
    </>
  )
}

export default AllOffersPage
