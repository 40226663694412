// Tailwind Merge
import CircularLoading from '@/components/ui/CircularLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactNode } from 'react'
import Skeleton from 'react-loading-skeleton'

type InfiniteScrollListProps = {
  dataLength?: number
  next: () => void
  hasMore: boolean
  refreshFunction?: () => void
  children?: ReactNode
  isLoading?: boolean
}

const InfiniteScrollList = ({
  children,
  next,
  refreshFunction,
  hasMore,
  dataLength,
  isLoading,
}: InfiniteScrollListProps) => {
  const _renderContent = () => {
    if (!dataLength) {
      return (
        <div className={'flex h-60 flex-col items-center justify-center'}>
          <span className={'text-2xl'}>😲</span>
          <span className={'mb-8 text-center text-sm font-semibold'}>Zatím tu nic není</span>
        </div>
      )
    }

    return (
      <ul className={'my-1 mb-3 flex w-full flex-col gap-2'}>
        {isLoading ? (
          <>
            <Skeleton count={20} className={'h-24 w-full'} />
          </>
        ) : (
          <>{children}</>
        )}
      </ul>
    )
  }

  return (
    <InfiniteScroll
      dataLength={dataLength || 0}
      next={next}
      scrollableTarget={'view-container'}
      hasMore={hasMore}
      refreshFunction={() => refreshFunction && refreshFunction()}
      pullDownToRefresh={!!refreshFunction && window.outerWidth < 768}
      pullDownToRefreshThreshold={100}
      pullDownToRefreshContent={
        <h3 className={'h-12 text-center'}>
          😊
          <br />
          Táhni dolů pro refresh
        </h3>
      }
      releaseToRefreshContent={
        <h3 className={'h-12 text-center'}>
          🥳
          <br />
          Pusť pro refresh
        </h3>
      }
      loader={
        <div className={'my-5 flex items-center justify-center'}>
          <CircularLoading />
        </div>
      }
      endMessage={
        <>
          {!!dataLength && (
            <div className={'mb-8 flex items-center justify-center '}>
              Jsi na konci, Víc tu toho není 🥱
            </div>
          )}
        </>
      }
    >
      {_renderContent()}
    </InfiniteScroll>
  )
}

export default InfiniteScrollList
