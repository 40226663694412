import { jwtDecode } from 'jwt-decode'
import { TAuth, useAuth } from '@/context/authContext.tsx'
import API_ROUTES from '@/constants/apiRoutes.ts'
import axios from 'axios'
import { axiosClient } from '@/lib/axios/axios.ts'

import config from '@/constants/config'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'

interface ILoginResponse {
  access_token: string
}

interface IRegisterApiInput {
  username: string
  password: string
  registrationRequestId: string
  isPublic: boolean
}

const useLogin = () => {
  const { updateAuth } = useAuth()
  const login = async (email: string, password: string) => {
    const res = await axios.post<ILoginResponse>(
      `${config.API_URL}${API_ROUTES.LOGIN}`,
      { email, password },
      {
        withCredentials: true,
      },
    )
    const token = jwtDecode<TAuth>(res.data.access_token)
    updateAuth(token)
  }

  return { login }
}

export const useSignUp = () => {
  const { updateAuth } = useAuth()
  const navigate = useNavigate()

  const signUp = async (data: IRegisterApiInput) => {
    const res = await axios.post<ILoginResponse>(
      `${config.API_URL}${API_ROUTES.REGISTRATION}`,
      data,
      {
        withCredentials: true,
      },
    )
    const token = jwtDecode<TAuth>(res.data.access_token)
    updateAuth(token)
    navigate(`${ROUTES.USER_DETAIL}/${token.sub}`)
  }

  return { signUp }
}

export const useRefreshToken = () => {
  const { updateAuth } = useAuth()
  const refresh = async () => {
    try {
      const res = await axiosClient.post<ILoginResponse>(API_ROUTES.REFRESH)
      const token = jwtDecode<TAuth>(res.data.access_token)
      updateAuth(token)
    } catch (e) {
      updateAuth(undefined)
    }
  }

  return { refresh }
}

export const useLogout = () => {
  const { updateAuth } = useAuth()
  const queryClient = useQueryClient()
  const logout = async () => {
    await axiosClient.post<ILoginResponse>(API_ROUTES.LOGOUT)
    updateAuth(undefined)
    queryClient.removeQueries()
  }

  return { logout }
}

export default useLogin
