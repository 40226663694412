// Axios
import axios from 'axios'
import { handleDates } from '@/utils/date.ts'
import config from '@/constants/config'

const axiosClient = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
})

axiosClient.interceptors.response.use(rep => {
  handleDates(rep.data)
  return rep
})

export { axiosClient }
