import PageContainer from '@/components/ui/PageContainer'
import PageHeading from '@/components/atom/PageHeading'
import Button from '@/components/ui/Button'
import BusinessAccountForm from '@/components/form/BusinessAccountForm'
import { useCreateBusinessProfile, useGetMyUser } from '@/api/queries/user.queries.ts'
import { TBusinessAccountSchema } from '@/components/form/BusinessAccountForm/BusinessAccountForm.tsx'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'
import ScreenLoading from '@/components/atom/ScreenLoading'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'

const BusinessAccountSettingsPage = () => {
  const { mutateAsync: createBusinessProfile, isPending } = useCreateBusinessProfile()
  const navigate = useNavigate()
  const { data: myUserData, isLoading: isMyUserDataLoading, isError } = useGetMyUser()

  if (isMyUserDataLoading) return <ScreenLoading />

  if (isError || !myUserData) return <GlobalErrorInfoMessageContent />

  const myUser = myUserData.data

  const _onSubmit = async (data: TBusinessAccountSchema) => {
    await createBusinessProfile(data)
    navigate(ROUTES.STRIPE_ONBOARDING_PAGE)
  }

  return (
    <PageContainer>
      <PageHeading
        title={'Fakturace'}
        backButton
        backlink={ROUTES.SETTINGS}
        rightContent={
          <>
            <Button
              label={'Další'}
              form={'business-detail-form'}
              isLoading={isPending}
              href={myUserData.data.businessProfile && ROUTES.STRIPE_ONBOARDING_PAGE}
            />
          </>
        }
      />
      <BusinessAccountForm
        onSubmit={_onSubmit}
        defaultValues={myUser.businessProfile}
        disabled={!!myUser.businessProfile}
      />
    </PageContainer>
  )
}

export default BusinessAccountSettingsPage
