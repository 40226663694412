import ModalHeading from '@/components/atom/ModalHeading'
import PasswordResetForm from '@/components/form/PasswordResetForm'
import { useGetPasswordReset } from '@/api/queries/passwordReset.queries.ts'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'

import ScreenLoading from '@/components/atom/ScreenLoading'

const PasswordResetPage = () => {
  const { id } = useParams()

  const { data, isLoading, error } = useGetPasswordReset(id)

  if (isLoading) return <ScreenLoading />

  if (!data || error)
    return (
      <InfoMessageContent
        heading='Odkaz není spráný 😢'
        message='Odkaz na obnovu hesla je buď expirovaný, nebo nebyl zadán správně.'
        actionButtonHref={ROUTES.LANDING}
        actionButtonLabel='Zpět ↩️'
      />
    )

  return (
    <div className={'flex w-full flex-col items-center gap-8'}>
      <img src={'/logo.svg'} />
      <ModalHeading heading={'Obnovení hesla📝'} subHeading={'Zadej nové heslo k tvému účtu'} />
      <PasswordResetForm passwordReset={data.data} />
    </div>
  )
}

export default PasswordResetPage
