// Tailwind Merge

import Modal from '@/components/modal/Modal'
import { useDeactivateOffer } from '@/api/queries/offer.queries.ts'
import Button from '@/components/ui/Button'

type OfferDeactivateModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
  offerId?: string
}

const OfferDeactivateModal = ({ onClose, isOpened, offerId }: OfferDeactivateModalProps) => {
  const { mutateAsync: deactivateOffer, isPending } = useDeactivateOffer()

  const _onDeactivate = async () => {
    await deactivateOffer(offerId)
    onClose && onClose()
  }

  return (
    <Modal onClose={onClose} isOpened={isOpened}>
      <div className={'flex flex-col items-center justify-center gap-2'}>
        <h1 className={'text-xl font-semibold'}>Opravdu si přeješ deaktivovat link?</h1>
        <p className={'text-gray-400'}>Po deaktivování linku již nebude možnost jeho zakoupení</p>
        <div className={'mt-10 flex w-full gap-4'}>
          <Button className={'flex-1'} label={'Zrušit'} variant={'neutral'} onClick={onClose} />
          <Button
            className={'flex-1'}
            label={'Deaktivovat link'}
            isLoading={isPending}
            onClick={_onDeactivate}
          />
        </div>
      </div>
    </Modal>
  )
}

export default OfferDeactivateModal
