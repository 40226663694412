// Tailwind Merge
import { twMerge } from 'tailwind-merge'

type ModalHeadingProps = {
  className?: string
  subHeading?: string
  heading?: string
}

const ModalHeading = ({ className, heading, subHeading }: ModalHeadingProps) => {
  return (
    <div className={twMerge('flex flex-col items-center justify-center', className)}>
      <h1 className={' text-center text-xl font-semibold'}>{heading}</h1>
      {subHeading && <span className={'text-center font-medium text-gray-500'}>{subHeading}</span>}
    </div>
  )
}

export default ModalHeading
