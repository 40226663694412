import { useParams } from 'react-router-dom'
import { useGetOfferPrivateById } from '@/api/queries/offer.queries.ts'

import PageHeading from '@/components/atom/PageHeading'
import { IconSettings, IconShare2 } from '@tabler/icons-react'
import { useGetOrdersByOfferId } from '@/api/queries/order.queries.ts'
import OrderListItem from '@/components/molecule/OrderListItem'
import PageSectionHeading from '@/components/atom/PageSectionHeading'
import { ROUTES } from '@/constants/routes.tsx'
import OfferStats from '@/components/molecule/OfferStats'
import OfferStateBadge from '@/components/atom/OfferStateBadge'
import { useState } from 'react'
import OfferShareModal from '@/components/modal/OfferShareModal'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'
import Button from '@/components/ui/Button'
import AlertCard from '@/components/ui/AlertCard'
import PageContainer from '@/components/ui/PageContainer'
import FileThumbnailWithPlayer from '@/components/organism/FileThumbnailWithPlayer'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'

const OfferPrivateDetailPage = () => {
  const [isShareModalOpened, setIsShareModalOpened] = useState(false)
  const { id } = useParams()
  const {
    data: offerResponse,
    isLoading: isOfferLoading,
    isError: isOfferFetchError,
  } = useGetOfferPrivateById(id)

  const {
    data: ordersResponse,
    isLoading: isOrdersLoading,
    isError: isOrdersFetchError,
  } = useGetOrdersByOfferId(id, 5, EOrderPaymentStatus.SUCCEED)

  if (isOfferLoading || isOrdersLoading) {
    return <ScreenLoading />
  }

  if (isOfferFetchError || isOrdersFetchError || !offerResponse || !ordersResponse) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.OFFERS} />
  }

  const offer = offerResponse.data

  const { name, files, uploadStatus } = offer
  const orders = ordersResponse?.pages[0]

  const _renderOfferName = () => {
    return <h1 className={'text-lg font-bold'}>{name}</h1>
  }

  return (
    <PageContainer>
      <PageHeading
        backButton
        title={'Detail linku'}
        rightContent={
          <>
            <Button
              size={'small'}
              variant={'secondary'}
              icon={IconSettings}
              href={`${ROUTES.OFFERS}/${id}/settings`}
              disabled={uploadStatus === EOfferUploadStatus.PROCESSING}
            />
            <Button
              size={'small'}
              variant={'secondary'}
              icon={IconShare2}
              onClick={() => setIsShareModalOpened(true)}
              disabled={uploadStatus === EOfferUploadStatus.PROCESSING}
            />
          </>
        }
      />
      <div className={'flex flex-col items-center gap-2'}>
        {_renderOfferName()}
        <OfferStateBadge offer={offer} />
      </div>
      <div className={'flex  items-center justify-center'}>
        <FileThumbnailWithPlayer
          file={files[0]}
          disablePlayer={offer.uploadStatus === EOfferUploadStatus.PROCESSING}
        />
      </div>
      {uploadStatus === EOfferUploadStatus.PROCESSING ? (
        <AlertCard
          content={'Probíhá zpracování médií, vrať se prosím k vytvořenému linku později'}
        />
      ) : (
        <>
          <OfferStats
            // revenues total always accessible in private offer
            revenuesTotal={offer?.revenuesTotal as number}
            successfulOrdersCount={offer?.successfullOrdersCount}
          />
          <div className={'flex flex-col'}>
            <PageSectionHeading
              title={`Objednávky (${orders?.meta.total})`}
              action={{ href: `${ROUTES.OFFERS}/${id}/orders`, label: 'Zobrazit vše' }}
            />
            <ul className={'-mt-1 flex w-full flex-col gap-2'}>
              {orders?.data?.map(order => <OrderListItem key={order.id} order={order} />)}
            </ul>
          </div>
        </>
      )}
      <OfferShareModal
        offer={offerResponse.data}
        isOpened={isShareModalOpened}
        onClose={() => setIsShareModalOpened(false)}
      />
    </PageContainer>
  )
}

export default OfferPrivateDetailPage
