// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconArrowBack } from '@tabler/icons-react'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@/components/ui/Button'

type PageHeadingProps = {
  className?: string
  title?: string
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  backButton?: boolean
  style?: React.CSSProperties
  backlink?: string
}

const PageHeading = ({
  className,
  title,
  leftContent,
  rightContent,
  backButton,
  style,
  backlink,
}: PageHeadingProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onClick = useCallback(() => {
    if (backlink) {
      navigate(backlink)
      return
    }

    if (location.key === 'default') {
      return navigate('/')
    }
    // navigate to previous page
    navigate(-1)
  }, [navigate, location])

  return (
    <div
      className={twMerge(
        'sticky top-0 z-10 flex w-full items-center justify-between bg-surface-50 px-1 py-2 ',
        className,
      )}
      style={style}
    >
      <div className={'flex-1'}>
        {backButton && (
          <Button icon={IconArrowBack} size={'small'} variant={'secondary'} onClick={onClick} />
        )}
        {leftContent}
      </div>
      <h1 className={twMerge('flex-1 text-center text-lg font-bold', className)}>{title}</h1>
      <div className={'flex flex-1 justify-end gap-2'}>{rightContent}</div>
    </div>
  )
}

export default PageHeading
