// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import Button from '@/components/ui/Button'

type PageSectionHeadingProps = {
  className?: string
  title?: string
  action?: {
    onClick?: () => void
    href?: string
    label: string
  }
}

const PageSectionHeading = ({ className, action, title }: PageSectionHeadingProps) => {
  return (
    <div className={twMerge('mb-3 flex w-full', action && 'justify-between', className)}>
      <h2 className={'font-semibold'}>{title}</h2>
      {action &&
        (action.href ? (
          <Button label={action?.label} size={'extra-small'} href={action.href} />
        ) : (
          <Button label={action?.label} size={'extra-small'} onClick={action?.onClick} />
        ))}
    </div>
  )
}

export default PageSectionHeading
