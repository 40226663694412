import animationData from '@/assets/lotties/cat.json'
import Lottie from 'react-lottie'

interface IProps {
  width?: number
  height?: number
}

const CatLottie = ({ width, height }: IProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  )
}

export default CatLottie
