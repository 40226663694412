// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { TFile } from '@/types/file.ts'
import { EFileType } from '@/types/enum/fileType.ts'
import React, { CSSProperties } from 'react'
import { IconFile, IconLock } from '@tabler/icons-react'
import { PlayIcon } from '@heroicons/react/20/solid'
import { formatPrice } from '@/utils/price'
import DurationBadge from '@/components/atom/DurationBadge'
import Badge from '@/components/ui/Badge'
import { durationToString } from '@/utils/date.ts'
import CustomTooltip from '@/components/ui/CustomTooltip'

type OfferFilePreviewProps = {
  className?: string
  file: TFile
  style?: CSSProperties
  price?: number
  showLock?: boolean
  type: 'locked' | 'private'
  leftTopContent?: React.ReactNode
  leftBottomContent?: React.ReactNode
  rightTopContent?: React.ReactNode
  rightBottomContent?: React.ReactNode
  allowPlay?: boolean
  onClick?: (file: TFile) => void
  showDuration?: boolean
  offerLimit?: number
  centerContent?: React.ReactNode
}

const FileThumbnail = ({
  className,
  file,
  style,
  price,
  showLock,
  type,
  leftTopContent,
  rightTopContent,
  rightBottomContent,
  leftBottomContent,
  allowPlay,
  onClick,
  showDuration,
  offerLimit,
  centerContent,
}: OfferFilePreviewProps) => {
  const thumbnailUrl = type === 'private' ? file.privateThumbnailUrl : file.lockedThumbnailUrl

  const __renderLimitBadge = () => {
    if (offerLimit)
      return (
        <Badge variant={'warning'}>
          Nastaven časový limit:
          {durationToString(offerLimit)}
          <CustomTooltip message={'Tvůrce linku nastavil na objenávku časový limit, '} />
        </Badge>
      )
  }
  const _renderContent = () => {
    if (file.fileType === EFileType.PHOTO || file.fileType === EFileType.VIDEO)
      return (
        <img
          className={'h-full max-h-full w-full object-cover'}
          src={thumbnailUrl}
          alt={file.fileName}
        />
      )

    if (file.fileType === EFileType.FILE)
      return (
        <div className={'flex h-full flex-col items-center justify-center border bg-gray-200 '}>
          <IconFile size={190} className={'text-gray-300'} />
        </div>
      )
  }

  return (
    <>
      <div
        className={twMerge(
          'relative aspect-square  w-full overflow-hidden rounded-3xl',
          className,
          onClick && 'cursor-pointer',
        )}
        style={style}
        onClick={() => onClick && onClick(file)}
      >
        {_renderContent()}
        <div
          className={
            'absolute flex h-fit w-fit flex-col items-center justify-center gap-2 text-4xl font-bold text-white'
          }
          style={{ left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', wordSpacing: '-7px' }}
        >
          {showLock && <IconLock size={90} />}
          {price && <>{formatPrice(price)} Kč</>}
          {centerContent}
          {allowPlay && file.fileType === 'VIDEO' && <PlayIcon width={95} />}
        </div>
        <div className={'absolute top-4 flex w-full justify-between px-3'}>
          <div>{leftTopContent}</div>
          <div>
            {rightTopContent}
            {showDuration && file.duration && <DurationBadge duration={file.duration} />}{' '}
          </div>
        </div>
        <div className={'absolute bottom-4 flex w-full justify-between px-3'}>
          <div>
            {leftBottomContent} {__renderLimitBadge()}
          </div>
          <div>{rightBottomContent}</div>
        </div>
      </div>
    </>
  )
}

export default FileThumbnail
