import { axiosClient } from '@/lib/axios/axios.ts'
import { TOffer } from '@/types/offer.ts'
import API_ROUTES, { getApiRoute } from '@/constants/apiRoutes.ts'
import { TPaginatedData, TPaginationParams } from '@/types/pagination.ts'
import { EOfferState } from '@/types/enum/offerState.ts'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'

type TCreateOfferApiInput = {
  price: number
  fileIds: string[]
  name: string
  orderAccessDurationLimit?: number | null
  caption?: string | null
  isPublic?: boolean
}

type TUpdateOfferApiInput = {
  offerId: string
  price: number
  name: string
}
export const createOffer = async (data: TCreateOfferApiInput) => {
  return axiosClient.post<TOffer>(API_ROUTES.OFFER, data)
}

export const updateOffer = async (data: TUpdateOfferApiInput) => {
  const { offerId, ...body } = data
  return axiosClient.put<TOffer>(`${API_ROUTES.OFFER}/${offerId}`, body)
}

export const getPrivateOfferDetailById = async (offerId: string) => {
  return axiosClient.get<TOffer>(`${API_ROUTES.OFFER_PRIVATE_DETAIL}/${offerId}`)
}

export const getPublicOfferDetailById = async (offerId: string) => {
  return axiosClient.get<TOffer>(`${API_ROUTES.OFFER_PUBLIC_DETAIL}/${offerId}`)
}

export interface IGetMyOffersApiQueryParams {
  paginationParams: TPaginationParams
  params?: { state?: EOfferState; uploadStatus?: EOfferUploadStatus }
}
export const getMyOffers = async (data?: IGetMyOffersApiQueryParams) => {
  const params = { ...data?.paginationParams, ...data?.params }
  return axiosClient.get<TPaginatedData<TOffer>>(`${API_ROUTES.MY_OFFERS}`, {
    params,
  })
}

export interface IGetUsersOffersApiQueryParams {
  paginationParams: TPaginationParams
  userId?: string
}
export const getUsersOffers = async (data?: IGetUsersOffersApiQueryParams) => {
  const params = { ...data?.paginationParams, userId: data?.userId }
  return axiosClient.get<TPaginatedData<TOffer>>(`${API_ROUTES.OFFER}/public/`, {
    params,
  })
}

export const deactivateOffer = async (offerId?: string) => {
  if (!offerId) throw new Error('Offer id is required')
  return axiosClient.post(`${getApiRoute(API_ROUTES.DEACTIVATE_OFFER, { offerId })}`)
}

export const activateOffer = async (offerId?: string) => {
  if (!offerId) throw new Error('Offer id is required')
  return axiosClient.post(`${getApiRoute(API_ROUTES.ACTIVATE_OFFER, { offerId })}`)
}
