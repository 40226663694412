import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createOrder,
  getOrderById,
  getOrderPrivateById,
  getOrdersByOfferId,
} from '@/api/requests/order.requests.ts'
import QUERY_KEYS from '@/constants/queryKeys.ts'
import { useCustomInfiniteQuery, useEnhancedQuery } from '@/api/api.ts'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus'
import toast from 'react-hot-toast'

export const useCreateOrder = () => {
  return useMutation({
    mutationFn: createOrder,
    onError: () => toast.error('Něco se pokazilo, zkus to prosím později'),
  })
}

export const useGetOrderById = (orderId?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.ORDERS, { orderId }],
      queryFn: () => getOrderById(orderId as string),
      enabled: !!orderId,
    }),
    invalidate: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDERS, { orderId }] }),
  }
}

export const useGetOrderPrivateById = (orderId?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.ORDERS_PRIVATE, { orderId }],
      queryFn: () => getOrderPrivateById(orderId as string),
      enabled: !!orderId,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORDERS_PRIVATE, { orderId }] }),
  }
}

export const useGetOrdersByOfferId = (
  offerId?: string,
  limit?: number,
  orderPaymentStatus?: EOrderPaymentStatus,
) => {
  const queryClient = useQueryClient()

  return {
    ...useCustomInfiniteQuery({
      limit,
      queryKey: [QUERY_KEYS.OFFER_ORDERS, offerId],
      queryFn: getOrdersByOfferId,
      fnParams: { offerId, orderPaymentStatus },
      enabled: !!offerId,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.OFFER_ORDERS, offerId] }),
    reset: () => queryClient.resetQueries({ queryKey: [QUERY_KEYS.OFFER_ORDERS, offerId] }),
  }
}
