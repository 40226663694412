// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { TPublicUser } from '@/types/user.ts'
import Avatar from '@/components/ui/Avatar'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'

type OfferUserAvatarProps = {
  className?: string
  user: TPublicUser
}

const OfferUserAvatar = ({ className, user }: OfferUserAvatarProps) => {
  return (
    <Link
      className={twMerge(
        'flex  items-center gap-2  rounded-2xl bg-white px-3 py-3 pr-8 drop-shadow-sm',
        className,
      )}
      to={`${ROUTES.USER_DETAIL}/${user.id}`}
    >
      <Avatar username={user.username} size={'small'} />
      <div className={' flex flex-col '}>
        <span className={'-mb-1 font-semibold capitalize'}>{user.username}</span>
        <span className={'text-sm text-gray-400'}>{user.publicOffersCount} Linků</span>
      </div>
    </Link>
  )
}

export default OfferUserAvatar
