// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import styles from './UploadCard.module.scss'
import React, { CSSProperties } from 'react'
import useUploadFiles from '@/api/useUploadFiles.ts'
import ProgressBarCircular from '@/components/atom/ProgressBarCircular'
import { TFile } from '@/types/file.ts'
import FileThumbnail from '@/components/molecule/FilePreviewThumbnail'
import Button from '@/components/ui/Button'

type UploadCardProps = {
  className?: string
  style?: CSSProperties
  onFileUpload?: (uploadedFile: TFile | null) => void
  uploadedFile?: TFile | null
}

const UploadCard = ({ className, style, onFileUpload, uploadedFile }: UploadCardProps) => {
  const { uploadFiles, progress, isLoading } = useUploadFiles()
  const _onUploadImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) return
    const uploadedFiles = await uploadFiles(files)
    onFileUpload && onFileUpload(uploadedFiles.data[0])
  }

  const renderContent = () => {
    if (uploadedFile)
      return (
        <FileThumbnail
          file={uploadedFile}
          className={'w-full'}
          type={'private'}
          rightTopContent={
            <Button
              size={'extra-small'}
              icon={IconTrash}
              onClick={() => onFileUpload && onFileUpload(null)}
            />
          }
        />
      )

    if (isLoading) return <ProgressBarCircular progressPercentage={progress} />

    return (
      <>
        <input type={'file'} onChange={_onUploadImageUpload} multiple />
        <div
          className={'flex items-center justify-center bg-primary-500 text-white'}
          style={{ width: 70, height: 70, borderRadius: '100%' }}
        >
          <IconPlus size={60} />
        </div>

        <h2 className={'text-lg font-medium'}>Nahraj soubor pro sdílení 🌄</h2>
        <span className={'text-sm font-light'}>Fotografie, video nebo soubor</span>
      </>
    )
  }

  return (
    <div
      className={twMerge(
        'flex  w-full flex-col items-center justify-center overflow-hidden  rounded-lg bg-surface-100 duration-300',
        !uploadedFile && 'hover:scale-101',
        isLoading && 'scale-101',
        className,
        styles.uploadCard,
      )}
      style={style}
    >
      {renderContent()}
    </div>
  )
}

export default UploadCard
