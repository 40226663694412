import ModalHeading from '@/components/atom/ModalHeading'
import RegistrationForm from '@/components/form/RegistrationForm'
import { useParams } from 'react-router-dom'
import { useGetRegistrationRequest } from '@/api/queries/registrationRequest.queries.ts'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import { ROUTES } from '@/constants/routes.tsx'
import ScreenLoading from '@/components/atom/ScreenLoading'
import PageContainer from '@/components/ui/PageContainer'

const RegistrationPage = () => {
  const { id } = useParams()

  const {
    data: registrationRequestData,
    isLoading,
    isNotFound,
    isError,
  } = useGetRegistrationRequest(id)

  if (isLoading) return <ScreenLoading />

  if (isNotFound || !registrationRequestData || isError)
    return (
      <InfoMessageContent
        heading='Odkaz není spráný 😢'
        message='Odkaz na obnovu hesla je buď expirovaný, nebo nebyl zadán správně.'
        actionButtonHref={ROUTES.LANDING}
        actionButtonLabel='Zpět ↩️'
      />
    )

  return (
    <PageContainer>
      <div className={'flex w-full flex-col items-center gap-8'}>
        <ModalHeading heading={'Registrace📝'} subHeading={'Doplň údaje k tvému profilu'} />
        <RegistrationForm registrationRequest={registrationRequestData.data} />
      </div>
    </PageContainer>
  )
}

export default RegistrationPage
