import React from 'react'

type TErrorHandlerProps = {
  children?: React.ReactNode
}
const AxiosErrorHandler = ({ children }: TErrorHandlerProps) => {
  return children
}

export default AxiosErrorHandler
