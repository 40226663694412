// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import Input from '@/components/ui/Input'
import Dropdown from '@/components/ui/Dropdown'
import { TOption } from '@/components/ui/Dropdown/Dropdown.tsx'
import { EBusinessType } from '@/types/enum/BusinessType.ts'
import { z } from 'zod'
import {
  companyNameSchema,
  businessTypeSchema,
  companyRegistrationIdSchema,
  companyVatStatusSchema,
  streetNumberSchema,
  vatIdSchema,
  citySchema,
  phoneNumberSchema,
  postalCodeSchema,
  streetSchema,
  firstNameSchema,
  lastNameSchema,
} from '@/utils/zod.ts'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ECompanyVatStatus } from '@/types/enum/CompanyVatStatus.ts'
import { isInstanceOfEnum } from '@/utils/enum.ts'
import { BusinessAccount } from '@/types/BusinessAccount.ts'
import { useEffect } from 'react'

const businessTypeOptions: TOption[] = [
  {
    label: 'Živnost',
    value: EBusinessType.OWN_ACCOUNT,
  },
  {
    label: 'Společnost',
    value: EBusinessType.COMPANY,
  },
  {
    label: 'Bez podnikání',
    value: EBusinessType.NONE,
  },
]

const companyVatStatusOptions: TOption[] = [
  {
    label: 'Plátce',
    value: ECompanyVatStatus.VAT_REGISTERED,
  },
  {
    label: 'Neplátce',
    value: ECompanyVatStatus.NONE,
  },

  {
    label: 'Osvobozen od daně',
    value: ECompanyVatStatus.FREE,
  },
]

const businessAccountSchema = z
  .object({
    businessType: businessTypeSchema,

    companyName: companyNameSchema.optional(),
    companyRegistrationId: companyRegistrationIdSchema.optional(),
    vatStatus: companyVatStatusSchema.optional(),
    vatId: vatIdSchema.optional(),

    firstName: firstNameSchema,
    lastName: lastNameSchema,
    streetNumber: streetNumberSchema.min(1, 'Číslo popisné je povinné'),
    city: citySchema.min(1, 'Město je povinné'),
    phoneNumber: phoneNumberSchema.min(0),
    postalCode: postalCodeSchema.min(0).min(1, 'PSČ je povinné'),
    street: streetSchema.min(1, 'Ulice je povinná'),
  })
  .refine(
    data =>
      data.businessType !== EBusinessType.COMPANY ||
      (data.companyName && data.companyName.length > 0),
    {
      message: 'Název společnosti je povinný',
      path: ['companyName'],
    },
  )
  .refine(
    data =>
      data.businessType === 'NONE' ||
      (data.companyRegistrationId && data.companyRegistrationId.length > 0),
    {
      message: 'IČO je povinné',
      path: ['companyRegistrationId'],
    },
  )
  .refine(
    data => data.businessType === 'NONE' || isInstanceOfEnum(data.vatStatus, ECompanyVatStatus),
    {
      message: 'Tato informace je povinná',
      path: ['vatStatus'],
    },
  )

export type TBusinessAccountSchema = z.infer<typeof businessAccountSchema>

type BusinessAccountFormProps = {
  className?: string
  onSubmit?: (data: TBusinessAccountSchema) => Promise<void>
  defaultValues?: BusinessAccount
  disabled?: boolean
}

const BusinessAccountForm = ({
  className,
  onSubmit,
  defaultValues,
  disabled,
}: BusinessAccountFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TBusinessAccountSchema>({
    resolver: zodResolver(businessAccountSchema),
    defaultValues,
  })

  const businessType = watch('businessType')

  const _onSubmit = handleSubmit(data => {
    onSubmit && !disabled && onSubmit(data)
  })

  useEffect(() => {
    reset({
      businessType: businessType,
    })
  }, [businessType, reset])

  return (
    <form
      className={twMerge('flex flex-col gap-4 pb-10', className)}
      id={'business-detail-form'}
      onSubmit={_onSubmit}
    >
      <Controller
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <Dropdown
            options={businessTypeOptions}
            label={'Typ podnikání'}
            onChange={onChange}
            selected={value}
            error={errors['businessType']}
            required
            disabled={disabled}
          />
        )}
        name={'businessType'}
        control={control}
      />

      {businessType && businessType !== EBusinessType.NONE && (
        <>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Dropdown
                options={companyVatStatusOptions}
                label={'Plátce dph'}
                onChange={onChange}
                selected={value}
                error={errors['vatStatus']}
                required
                disabled={disabled}
              />
            )}
            name={'vatStatus'}
            control={control}
          />
          {businessType === EBusinessType.COMPANY && (
            <Input
              label={'Název společnosti'}
              placeholder={'Pepíkova s.r.o.'}
              required
              id={'companyName'}
              register={register}
              name={'companyName'}
              error={errors['companyName']}
              disabled={disabled}
            />
          )}
          <Input
            label={'Identifikační číslo (IČO)'}
            placeholder={'1234567890'}
            required
            id={'companyRegistrationId'}
            register={register}
            name={'companyRegistrationId'}
            error={errors['companyRegistrationId']}
            disabled={disabled}
          />
          <Input
            label={'Daňové identifikační číslo (DIČ)'}
            placeholder={'CZ1234567890'}
            id={'vatId'}
            register={register}
            name={'vatId'}
            error={errors['vatId']}
            disabled={disabled}
          />
        </>
      )}
      <Input
        label={'Jméno'}
        placeholder={'Čestmír'}
        required
        id={'firstName'}
        register={register}
        name={'firstName'}
        error={errors['firstName']}
        disabled={disabled}
      />
      <Input
        label={'Příjmení'}
        placeholder={'Mázl'}
        required
        id={'lastName'}
        register={register}
        name={'lastName'}
        error={errors['lastName']}
        disabled={disabled}
      />
      <Input
        label={'Město'}
        placeholder={'Brno'}
        required
        id={'lastName'}
        register={register}
        name={'city'}
        error={errors['city']}
        disabled={disabled}
      />
      <Input
        label={'Ulice'}
        placeholder={'Pražská'}
        required
        id={'street'}
        register={register}
        name={'street'}
        error={errors['street']}
        disabled={disabled}
      />
      <Input
        label={'číslo popisné'}
        placeholder={'96'}
        required
        id={'streetNumber'}
        register={register}
        name={'streetNumber'}
        error={errors['streetNumber']}
        disabled={disabled}
      />
      <Input
        label={'PSČ'}
        placeholder={'182 00'}
        required
        id={'postalCode'}
        register={register}
        name={'postalCode'}
        error={errors['postalCode']}
        disabled={disabled}
      />
      <Input
        label={'Telefon'}
        placeholder={'+420 111 111 111'}
        required
        id={'phoneNumber'}
        register={register}
        name={'phoneNumber'}
        error={errors['phoneNumber']}
        disabled={disabled}
      />
    </form>
  )
}

export default BusinessAccountForm
