// Tailwind Merge
import { IconX } from '@tabler/icons-react'
import { TFile } from '@/types/file.ts'
import { EFileType } from '@/types/enum/fileType.ts'

type MediaModalProps = {
  file: TFile
  isOpened?: boolean
  onClose?: () => void
}

const MediaPlayerModal = ({ file, onClose, isOpened }: MediaModalProps) => {
  if (!isOpened) return null

  const _renderMedium = () => {
    if (file.fileType === EFileType.VIDEO) {
      return (
        <video
          src={file.fileUrl}
          controls
          className='relative h-full max-h-[100vw]
      w-full object-contain'
        />
      )
    }
    if (file.fileType === EFileType.PHOTO)
      return (
        <img
          alt={file.fileName}
          className='relative h-full max-h-[100vw]
      w-full object-contain'
          src={file.fileUrl}
        />
      )
  }

  return (
    <div className='fixed right-0 top-0 z-50 h-screen w-screen'>
      <div className='h-full max-h-dvh w-full '>
        <div className='media-lightbox-img relative flex  h-full max-h-screen min-h-screen w-full items-center overflow-hidden bg-black object-contain'>
          <img
            alt=''
            role='presentation'
            className='absolute left-0 top-1/2 h-full w-full  -translate-y-1/2 scale-[1.2] object-cover opacity-30 blur-lg'
            src={file.privateThumbnailUrl}
          />
          {_renderMedium()}
        </div>
      </div>
      <button
        className={'absolute right-4 top-4 text-3xl text-white'}
        onClick={() => onClose && onClose()}
      >
        <IconX width={50} height={50} />
      </button>
    </div>
  )
}

export default MediaPlayerModal
