import { useGetMyUser } from '@/api/queries/user.queries.ts'
import { useEffect, useState } from 'react'

const useCalculateFee = (price: number) => {
  const { data } = useGetMyUser()
  const [fee, setFee] = useState(0)
  const constantFeeRateLimit = +import.meta.env.VITE_APP_CONSTANT_FEE_RATE_LIMIT
  const constantFeeRate = +import.meta.env.VITE_APP_CONSTANT_FEE_RATE

  useEffect(() => {
    const feeRate = data?.data?.businessProfile?.assignedFee
    if (price <= constantFeeRateLimit) {
      setFee(constantFeeRate)
      return
    }

    if (feeRate) {
      setFee(price * feeRate)
      return
    }
  }, [price])

  return {
    fee,
    earningsAfterFee: price - fee,
  }
}

export default useCalculateFee
