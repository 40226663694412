// Tailwind Merge
import InfoMessageContent from '../InfoMessageContent'

type GlobalErrorInfoMessageContentProps = {
  backLink?: string
}

const GlobalErrorInfoMessageContent = ({ backLink }: GlobalErrorInfoMessageContentProps) => {
  return (
    <InfoMessageContent
      heading='Něco se pokazilo😖'
      message='Něco se pokazilo, zkus to prosím později'
      actionButtonHref={backLink}
      actionButtonLabel='Zpět↩️'
    />
  )
}

export default GlobalErrorInfoMessageContent
