import { useGetMyUser, useGetStripeOnboarding } from '@/api/queries/user.queries.ts'
import CircularLoading from '@/components/ui/CircularLoading'
import PageContainer from '@/components/ui/PageContainer'
import PageHeading from '@/components/atom/PageHeading'
import Button from '@/components/ui/Button'
import { EBusinessAccountStatus } from '@/types/enum/businessAccountStatus.ts'
import { ROUTES } from '@/constants/routes.tsx'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'

const StripeOnboardingPage = () => {
  const {
    data: stripeOnboardingData,
    isLoading: isStripeOnboardingLoading,
    isError: isStripeOnboardingError,
  } = useGetStripeOnboarding()
  const { data: myUserData, isLoading: isMyUserLoading, isError: isMyUserError } = useGetMyUser()

  if (isStripeOnboardingLoading || isMyUserLoading) return <CircularLoading />

  if (!stripeOnboardingData || !myUserData || isMyUserError || isStripeOnboardingError)
    return (
      <GlobalErrorInfoMessageContent backLink={`${ROUTES.USER_DETAIL}/${myUserData?.data.id}`} />
    )

  const onboarding = stripeOnboardingData.data
  const myUser = myUserData.data

  const _renderStartOnboarding = () => {
    if (myUser.businessProfile?.businessAccountStatus === EBusinessAccountStatus.ACTIVE) {
      return (
        <div
          className={
            'flex max-w-96 flex-col items-center justify-center gap-2 rounded-xl border-2 border-primary-500 p-8 py-12 text-center'
          }
        >
          <h2 className={'text-center text-lg font-semibold text-primary-600'}>
            Stripe účet připojen🥳
          </h2>
          <p className={'subtitle'}>Múžeš začít monetizovat svůj content</p>
          <Button label={'Začít prodávat💰'} className={'w-6/12'} href={ROUTES.DASHBOARD} />
        </div>
      )
    }

    if (myUser.businessProfile?.businessAccountStatus === EBusinessAccountStatus.PENDING) {
      return (
        <div className={'flex w-full flex-col gap-2 text-center'}>
          <h2 className={'text-center text-lg font-semibold text-primary-600'}>
            Probíhá schvalování účtu⏳
          </h2>
          <p className={'subtitle'}>Může to chvíli trvat, vrať se později</p>
          <Button
            variant={'neutral'}
            size={'large'}
            className={'w-full'}
            href={onboarding.url}
            label={
              <span>
                Připojit ke <b className={'text-indigo-500'}>Stripe</b>
              </span>
            }
          />
        </div>
      )
    }

    return (
      <>
        <p className={'text-center font-medium text-gray-500'}>
          Připoj si k Likeer.cz svůj Stripe účet pro vyplácení peněz
        </p>
        <Button
          variant={'neutral'}
          size={'large'}
          className={'w-full'}
          href={onboarding.url}
          label={
            <span>
              Připojit ke <b className={'text-indigo-500'}>Stripe</b>
            </span>
          }
        />
      </>
    )
  }

  return (
    <PageContainer className={'items-center'}>
      <PageHeading title={'Stripe'} backButton backlink={ROUTES.BUSINESS_SETTINGS} />

      {_renderStartOnboarding()}
      <div className={'flex flex-col gap-12 bg-primary-50 p-8'}>
        <div>
          <h2 className={'mb-2 font-bold text-primary-600'}>Jak to funguje ?</h2>
          <p className={'font-medium text-gray-500'}>
            Propoj si svůj Herohero profil se Stripe a začni přijímat platby od svých fanoušků. Svou
            výplatu obdržíš vždy v prvním pracovním dni v měsíci.
          </p>
        </div>
        <div>
          <h2 className={'mb-2 font-bold text-primary-600'}>Poplatky</h2>
          <p className={'font-medium text-gray-500'}>
            Herohero si účtuje poplatek 10 % pro účely údržby a zlepšování naší služb
          </p>
        </div>
        <div>
          <h2 className={'mb-2 font-bold text-primary-600'}>Podporované druhy podnikání</h2>
          <p className={'font-medium text-gray-500'}>
            Pokud potřebuješ pomoct s nastavením svého účtu nebo se chceš dozvědět více o Herohero,
            podívej se do naší sekce Nápověda. Můžeš nás kontaktovat také přes e-mail
            info@herohero.co. Vždy rádi pomůžeme.
          </p>
        </div>
      </div>
    </PageContainer>
  )
}

export default StripeOnboardingPage
