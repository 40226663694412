import { TPaginatedData, TPaginationParams } from '@/types/pagination.ts'
import {
  UseQueryOptions,
  QueryKey,
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios'
type TCustomInfiniteQuery<
  T,
  DP extends { paginationParams: TPaginationParams },
  D = Exclude<DP, 'paginationParams'>,
> = {
  queryKey: QueryKey
  queryFn: (data: DP) => Promise<AxiosResponse<TPaginatedData<T>>>
  limit?: number
  fnParams: D
  enabled?: boolean
}

export const useCustomInfiniteQuery = <
  T,
  DP extends { paginationParams: TPaginationParams },
  D extends Omit<DP, 'paginationParams'>,
>({
  queryKey,
  queryFn,
  limit = 10,
  fnParams,
  enabled = true,
}: TCustomInfiniteQuery<T, DP, D>) =>
  useInfiniteQuery({
    queryKey,
    queryFn: async context => {
      const res = await queryFn({
        ...fnParams,
        paginationParams: { limit, page: context.pageParam },
      } as unknown as DP)
      return res.data
    },
    enabled,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage.meta.total <= lastPageParam * limit) return null
      return lastPageParam + 1
    },
  })

export const useEnhancedQuery = <T, QError extends Error, TQueryFnData, QKey extends QueryKey>(
  options: UseQueryOptions<T, QError, TQueryFnData, QKey>,
): UseQueryResult<T, QError> & { isNotFound: boolean } => {
  const queryResult = useQuery<T, QError, TQueryFnData, QKey>(options)
  const { error } = queryResult

  const isNotFound =
    error instanceof AxiosError && error.response?.status === HttpStatusCode.NotFound

  return { ...queryResult, isNotFound } as unknown as UseQueryResult<T, QError> & {
    isNotFound: boolean
  }
}
