import PageHeading from '@/components/atom/PageHeading'
import PageSectionHeading from '@/components/atom/PageSectionHeading'
import { useGetMyOffers } from '@/api/queries/offer.queries.ts'
import OfferPrivateListItem from '@/components/molecule/OfferPrivateListItem'
import { EOfferState } from '@/types/enum/offerState.ts'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'
import PageContainer from '@/components/ui/PageContainer'
import { ROUTES } from '@/constants/routes.tsx'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'

const OffersPage = () => {
  const {
    data: activeOffersRes,
    isLoading: isLoadingActiveOffers,
    isError: isActiveOffersError,
  } = useGetMyOffers(EOfferState.ACTIVE, 3, EOfferUploadStatus.READY)

  const {
    data: deactivatedOffersRes,
    isLoading: isLoadingDeactivatedOffers,
    isError: isDeactivatedOffersError,
  } = useGetMyOffers(EOfferState.DEACTIVATED, 3)

  const {
    data: processingOffers,
    isLoading: isLoadingProcessingOffers,
    isError: isProcessingOffersError,
  } = useGetMyOffers(undefined, undefined, EOfferUploadStatus.PROCESSING)

  if (isLoadingDeactivatedOffers || isLoadingActiveOffers || isLoadingProcessingOffers) {
    return <ScreenLoading />
  }

  if (
    !activeOffersRes ||
    !deactivatedOffersRes ||
    !processingOffers ||
    isActiveOffersError ||
    isDeactivatedOffersError ||
    isProcessingOffersError
  ) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.DASHBOARD} />
  }

  const activatedOffersData = activeOffersRes.pages[0]
  const deactivatedOffersData = deactivatedOffersRes.pages[0]
  const processingOffersData = processingOffers.pages[0]

  return (
    <PageContainer>
      <PageHeading title={'Linky🙋'} />
      <div className={'flex w-full flex-col gap-4'}>
        {processingOffersData.data.length > 0 && (
          <div className={'flex flex-col gap-4'}>
            <PageSectionHeading title={`Zpracovávané linky(${processingOffersData.meta.total})`} />
            <ul className={'flex flex-col gap-2'}>
              {processingOffersData.data.map(offer => (
                <OfferPrivateListItem key={offer.id} offer={offer} />
              ))}
            </ul>
          </div>
        )}
        <div className={'flex flex-col gap-4'}>
          <PageSectionHeading
            title={`Aktivní linky(${activatedOffersData.meta.total})`}
            action={{
              href: `${ROUTES.ALL_OFFERS}/ACTIVE`,
              label: 'Zobrazit všechny',
            }}
          />
          <ul className={'flex flex-col gap-2'}>
            {activatedOffersData.data.map(offer => (
              <OfferPrivateListItem key={offer.id} offer={offer} />
            ))}
          </ul>
        </div>
        <div className={'flex flex-col gap-4'}>
          <PageSectionHeading
            title={`Deaktivované linky(${deactivatedOffersData.meta.total})`}
            action={{
              href: `${ROUTES.ALL_OFFERS}/DEACTIVATED`,
              label: 'Zobrazit všechny',
            }}
          />
          <ul className={'flex flex-col gap-2'}>
            {deactivatedOffersData.data.map(offer => (
              <OfferPrivateListItem key={offer.id} offer={offer} />
            ))}
          </ul>
        </div>
        <div></div>
      </div>
    </PageContainer>
  )
}

export default OffersPage
