// SEO.tsx
import React from 'react'
import { Helmet } from 'react-helmet'

interface SEOProps {
  title: string
  description?: string
  keywords?: string
  author?: string
  image?: string
  url?: string
}

const SEO: React.FC<SEOProps> = ({ title, description, keywords, author, image, url }) => (
  <Helmet>
    <title>{title}</title>
    {description && <meta name='description' content={description} />}
    {keywords && <meta name='keywords' content={keywords} />}
    {author && <meta name='author' content={author} />}
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    <link rel='canonical' href={url || window.location.href} />

    {/* Open Graph Meta Tags */}
    {image && <meta property='og:image' content={image} />}
    {url && <meta property='og:url' content={url} />}
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:type' content='website' />

    {/* Twitter Card Meta Tags */}
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    {image && <meta name='twitter:image' content={image} />}
    {url && <meta name='twitter:url' content={url} />}
  </Helmet>
)

export default SEO
