// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { ChangeEvent, useState } from 'react'
import useCalculateFee from '@/hooks/useCalculateFee.ts'
import { formatPrice } from '@/utils/price.ts'
import config from '@/constants/config.ts'

type PriceInputProps = {
  className?: string
  onChange?: (value: number) => void
  disabled?: boolean
  error?: string
}

const PriceInput = ({ className, onChange, disabled, error }: PriceInputProps) => {
  const [value, setValue] = useState<number>(0)
  const [isEdited, setIsEdited] = useState(false)
  const { earningsAfterFee } = useCalculateFee(value)
  const minPrice = config.MIN_PRICE
  const maxPrice = config.MAX_PRICE

  const formatWithSpaces = (inputValue: number) => {
    // Remove non-numeric characters for simplicity

    const numericValue = String(inputValue / 100).replace(/\D+/g, '')

    // Format with spaces as thousand separators
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setIsEdited(true)
    let numberValue = parseFloat(value.replace(' ', '')) * 100
    if (numberValue > maxPrice) numberValue = maxPrice

    setValue(isNaN(numberValue) ? 0 : numberValue)
    onChange && onChange(numberValue)
  }

  return (
    <div className={'flex flex-col items-center justify-center gap-1'}>
      <div className='flex  w-36 items-center justify-center rounded-3xl bg-surface-100 p-3 '>
        <input
          className={twMerge(
            ' w-full bg-transparent text-center text-2xl font-medium placeholder:text-black focus:outline-none  focus:placeholder:opacity-0',
            disabled && 'text-surface-500 placeholder:text-surface-500 ',
            className,
          )}
          value={formatWithSpaces(value)}
          onChange={handleChange}
          placeholder={'00'}
          disabled={disabled}
          inputMode='decimal'
        />
        <span
          className={twMerge(
            'text-lg font-semibold',
            disabled && 'text-surface-500 placeholder:text-surface-500',
          )}
        >
          Kč
        </span>
        <div></div>
      </div>
      {!isEdited && (
        <span className={twMerge('text-xs', disabled && 'text-surface-500')}>Nastav cenu</span>
      )}
      {isEdited && (
        <span className={twMerge('text-sm', disabled && 'text-surface-500')}>
          Odměna pro vás:{' '}
          <b className={'font-semibold text-primary-500'}>
            {value >= minPrice && formatPrice(earningsAfterFee)}kč
          </b>
        </span>
      )}
      {error && <span className={twMerge('text-xs text-red-600')}>{error}</span>}
    </div>
  )
}

export default PriceInput
