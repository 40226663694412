// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'

type AdvertiseBannerProps = {
  className?: string
}

const AdvertiseBanner = ({ className }: AdvertiseBannerProps) => {
  return (
    <Link
      to={ROUTES.DASHBOARD}
      className={twMerge(
        'flex max-h-52 items-center justify-between overflow-hidden rounded-3xl bg-primary-500 p-4 text-white',
        className,
      )}
    >
      <div className={'flex flex-col gap-1'}>
        <span className={'text-4xl font-bold'}>Zpeněžni svůj content</span>
        <span>Vyzkoušej Linkeer.cz</span>
      </div>
      <div className={'flex max-h-52 items-center justify-center overflow-hidden'}>
        <img
          src={'/img/emoji/emoji-2.png'}
          alt={'banner image'}
          className={'max-h-full object-contain'}
        />
      </div>
    </Link>
  )
}

export default AdvertiseBanner
