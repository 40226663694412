import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createBusinessProfile,
  getMyUser,
  getStripeOnboarding,
  getUserById,
  updateMyUser,
} from '@/api/requests/user.requests.ts'
import QUERY_KEYS from '@/constants/queryKeys.ts'
import queryClient from '@/lib/queryClient'
import { useEnhancedQuery } from '@/api/api.ts'
import { AxiosError, HttpStatusCode } from 'axios'
import toast from 'react-hot-toast'
import { useAuth } from '@/context/authContext.tsx'

export const useUpdateMyUser = () => {
  const { auth } = useAuth()
  return useMutation({
    mutationFn: updateMyUser,
    onSuccess: () => {
      toast.success('Profil úspěšně upraven')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER, auth?.sub] })
    },
    onError: e => {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Conflict) {
          toast.error('Tento název profilu již používá někdo jiný')
          return
        }
      }
      toast.error('Nelze upravit profil, zkus to prosím později')
      return
    },
  })
}

export const useCreateBusinessProfile = () => {
  return useMutation({
    mutationFn: createBusinessProfile,
    onSuccess: () => {
      toast.success('Fakturační údaje uloženy')
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_USER, QUERY_KEYS.MY_PROFILE] })
    },
    onError: () => {
      toast.error('Něco se nepovedlo, zkus to prosím později')
    },
  })
}

export const useGetStripeOnboarding = () => {
  const queryClient = useQueryClient()

  return {
    ...useQuery({
      queryKey: [QUERY_KEYS.STRIPE_ONBOARDING],
      queryFn: () => getStripeOnboarding(),
    }),
    invalidate: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.STRIPE_ONBOARDING] }),
  }
}

export const useGetMyUser = () => {
  const queryClient = useQueryClient()

  return {
    ...useQuery({
      queryKey: [QUERY_KEYS.MY_USER],
      queryFn: () => getMyUser(),
    }),
    invalidate: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.MY_USER] }),
  }
}

export const useGetUserById = (userId?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.USER, userId],
      queryFn: () => getUserById(userId as string),
      enabled: !!userId,
    }),
    invalidate: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER, userId] }),
  }
}
