import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.scss'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from './lib/queryClient.ts'
import config from './constants/config.ts'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://9974932d61335ced9c44ac19144cfb98@o4507928219156480.ingest.de.sentry.io/4507928220729424',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
    {config.NODE_ENV === 'develop' && <ReactQueryDevtools client={queryClient} position='left' />}
  </React.StrictMode>,
)
