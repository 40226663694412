import { twMerge } from 'tailwind-merge'
import { FieldError } from 'react-hook-form'
import { ChangeEvent, ReactNode } from 'react'

export type TOption = {
  label: string
  value?: string | number
}

interface IDropdownProps {
  options: TOption[]
  className?: string
  label?: string | ReactNode
  required?: boolean
  id?: string
  onChange?: (value?: string) => void
  selected?: string
  error?: FieldError
  disabled?: boolean
}

function Dropdown({
  options,
  className,
  label,
  required,
  id,
  error,
  onChange,
  selected,
  disabled,
}: IDropdownProps) {
  const _onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      if (!e.target.value.length) {
        onChange(undefined)
        return
      }

      onChange(e.target.value)
    }
  }

  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      {label && (
        <label className={twMerge('text-sm')} htmlFor={id}>
          <span>{label}</span>
          {required && <span className='text-red-500'>{`*`}</span>}
        </label>
      )}
      <div>
        <select
          className={twMerge(
            'peer w-full rounded-xl border   border-gray-300  px-4 py-4 placeholder:text-gray-500 focus:outline-green-500',
            disabled && `cursor-not-allowed bg-gray-100 text-gray-400`,
          )}
          onChange={_onChange}
          value={selected || undefined}
          disabled={disabled}
        >
          <option value={undefined}></option>
          {options.map((option: TOption) => (
            <option value={option.value} key={`${option.value}`}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && <div className='text-xs text-red-500'>{error.message}</div>}
    </div>
  )
}

export default Dropdown
