// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconAlertCircle } from '@tabler/icons-react'
import { ReactNode } from 'react'

type AlertCardProps = {
  className?: string
  content: ReactNode
}

const AlertCard = ({ className, content }: AlertCardProps) => {
  return (
    <div className={twMerge('flex gap-4  rounded-lg bg-orange-100 p-4 text-orange-600', className)}>
      <div>
        <IconAlertCircle />
      </div>
      <div>{content}</div>
    </div>
  )
}

export default AlertCard
