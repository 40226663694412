import { ReactNode, useEffect, useState } from 'react'
import ScreenLoading from '@/components/atom/ScreenLoading/ScreenLoading'

import { AnimatePresence, motion } from 'framer-motion'
import { useRefreshToken } from '@/api/auth.ts'
import { useAuth } from '@/context/authContext'

const PersistLoginLayout = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true)
  const auth = useAuth()
  const { refresh } = useRefreshToken()
  useEffect(() => {
    const verifyRefreshToken = async () => {
      await refresh()
      setIsLoading(false)
    }

    !auth.auth ? verifyRefreshToken() : setIsLoading(false)
  }, [auth.auth, refresh])

  return (
    <AnimatePresence mode={'wait'}>
      {isLoading && (
        <motion.div
          key={'loading'}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5 }}
        >
          <ScreenLoading className='h-screen w-screen' />
        </motion.div>
      )}

      {!isLoading && (
        <motion.div
          key={'content'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default PersistLoginLayout
