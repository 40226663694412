// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconAlertCircle, IconX } from '@tabler/icons-react'
import { useState } from 'react'
import Button from '@/components/ui/Button'

type InfoMessageProps = {
  className?: string
  message?: string
}

const InfoMessage = ({ className, message }: InfoMessageProps) => {
  const [isOpened, setIsOpened] = useState(true)

  if (!isOpened) return null

  return (
    <div
      className={twMerge(
        'relative flex w-full flex-col bg-primary-50 px-6 py-8 pt-10 text-primary-600',
        className,
      )}
    >
      <Button
        variant={'plain'}
        className={'absolute right-2 top-2 text-gray-400'}
        size={'small'}
        icon={IconX}
        onClick={() => setIsOpened(false)}
      />
      <div className={'flex gap-2'}>
        <IconAlertCircle className={'-ml-3 min-h-6 min-w-6'} />
        <p>{message}</p>
      </div>
    </div>
  )
}

export default InfoMessage
