// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconCheck, IconHourglassEmpty, IconSettings } from '@tabler/icons-react'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus.tsx'

type OrderStateBoxProps = {
  className?: string
  state: EOrderPaymentStatus
  size?: 'small' | 'large'
}

const OrderStateBox = ({ className, state, size }: OrderStateBoxProps) => {
  const stateIcon = {
    [EOrderPaymentStatus.PENDING]: IconHourglassEmpty,
    [EOrderPaymentStatus.SUCCEED]: IconCheck,
    [EOrderPaymentStatus.PROCESSING]: IconSettings,
  }

  const Icon = stateIcon[state]

  return (
    <div
      className={twMerge(
        'flex h-14 w-14 items-center justify-center rounded-md border-[3px] p-1 ',
        state === EOrderPaymentStatus.SUCCEED && 'border-green-600 text-green-600',
        state === EOrderPaymentStatus.PENDING && 'border-yellow-600 text-yellow-600',
        state === EOrderPaymentStatus.PROCESSING && 'border-yellow-600 text-yellow-600',
        size === 'large' && 'h-24 w-24 border-4',
        className,
      )}
    >
      <Icon className={'min-w-24'} stroke={3} size={size === 'large' ? 45 : undefined} />
    </div>
  )
}

export default OrderStateBox
