// Query client
import { QueryClient } from '@tanstack/react-query'

import { AxiosError, HttpStatusCode } from 'axios'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120000,
      retry: (_failureCount, error) => {
        if (error instanceof AxiosError) {
          if (
            error?.response?.status &&
            [HttpStatusCode.NotFound, HttpStatusCode.Gone].includes(error.response?.status)
          )
            return false
        }
        return _failureCount < 2
      },
    },
  },
})

export default queryClient
