import { useNavigate, useParams } from 'react-router-dom'
import { useGetOrderById } from '@/api/queries/order.queries.ts'
import AboutList from '@/components/molecule/AboutList'
import { formatDateToString } from '@/utils/date.ts'
import AccessSettingsCard from '@/components/atom/AccessSettingsCard'
import MediaPlayerModal from '@/components/modal/MediaPlayerModal'
import { useState } from 'react'
import FileThumbnailWithPlayer from '@/components/organism/FileThumbnailWithPlayer'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus.tsx'

import { AxiosError } from 'axios'
import TOrderAccessLimitExceededErrorBody from '@/types/OrderAccessLimitExceededErrorBody'
import { ROUTES } from '@/constants/routes.tsx'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'
import PageContainer from '@/components/ui/PageContainer'

const OrderDetailCustomerPage = () => {
  const { id } = useParams()
  const { data, isLoading, error, isNotFound } = useGetOrderById(id)
  const [isMediaModalOpened, setIsMediaModalOpened] = useState(false)
  const navigate = useNavigate()

  if (error && error instanceof AxiosError && error.response?.status === 410) {
    const offerId = (error.response.data as TOrderAccessLimitExceededErrorBody).offerId
    return (
      <InfoMessageContent
        heading='Objednávka expirovala 😥'
        message='Byl překročen časový limit zobrazení objednávky, nastavený tvůrcem obsahu'
        actionButtonLabel='Přejít na původní link ➡️'
        onActionButtonClick={() => navigate(ROUTES.OFFERS + offerId)}
      />
    )
  }

  if (isLoading) {
    return <ScreenLoading />
  }

  if (isNotFound) {
    return (
      <InfoMessageContent
        heading='Objednávku nenalezena.'
        message='Objednávku se nepodařilo najít, zkontroluj si správnost odkazu.'
      />
    )
  }

  if (!data?.data) {
    return <GlobalErrorInfoMessageContent />
  }

  const { data: order } = data

  if (order.paymentStatus !== EOrderPaymentStatus.SUCCEED) {
    return (
      <InfoMessageContent
        heading='Čeká se na platbu💸'
        message='Čekáme na potvrzení od platební brány, po uhrazení ti bude obsah zpřistupněn 🥳'
        actionButtonHref={ROUTES.OFFER_DETAIL_PUBLIC + order.offer.id}
        actionButtonLabel='Přejít na původní link ➡️'
        emojiVariant='3'
      />
    )
  }

  const { orderFiles, price, accessSettings, accessibleUntil, createdAt, offer, shortId } = order
  const orderFile = orderFiles[0]

  return (
    <PageContainer className={'flex flex-col items-center gap-12 '}>
      <div className={'flex w-full flex-col gap-3'} style={{ maxWidth: 450 }}>
        <FileThumbnailWithPlayer file={orderFile.file} />
        <h1 className={'ml-1 w-full text-center text-2xl font-semibold'}>{offer.name}</h1>
      </div>
      <div className={'flex w-full flex-col gap-3'}>
        {accessibleUntil && (
          <AccessSettingsCard accessSettings={accessSettings} accessibleUntil={accessibleUntil} />
        )}
        <AboutList
          heading={'Detail objednávky'}
          items={[
            {
              label: `Č. objednávky`,
              value: shortId,
            },
            {
              value: `${price} Kč`,
              label: 'Cena',
            },
            {
              label: `Datum objednávky`,
              value: formatDateToString(createdAt),
            },
          ]}
        />
      </div>
      <MediaPlayerModal
        file={orderFile.file}
        isOpened={isMediaModalOpened}
        onClose={() => setIsMediaModalOpened(false)}
      />
    </PageContainer>
  )
}

export default OrderDetailCustomerPage
