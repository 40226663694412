// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import AdvertiseBanner from '@/components/molecule/AdvertiseBanner'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  return (
    <div className={'flex flex-col gap-10  px-6 pt-4'}>
      <AdvertiseBanner />
      <ul className={'flex flex-col gap-2'}>
        <Link to={ROUTES.DASHBOARD} className={'text-2xl font-bold'}>
          Přihlásit se
        </Link>
        <Link to={ROUTES.DASHBOARD} className={'text-2xl font-bold'}>
          Registrace
        </Link>
        <a className={'text-2xl font-bold'}>Obchodní podmínky</a>
        <a className={'text-2xl font-bold'}>O nás</a>
      </ul>
      <ul
        className={twMerge(
          'flex w-full flex-col gap-3 border-t  border-t-surface-200 pt-4 text-surface-500',
          className,
        )}
      >
        <li className={'font-semibold'}>Kontaktuj nás</li>
        <li>tel: +420 602 145 409</li>
        <li>email: info@posli.to</li>
        <li>Pied piper solutions s.r.o.</li>
        <li>Kurzova 223/24 182 00</li>
        <li>IČO: 12018009123</li>
      </ul>
    </div>
  )
}

export default Footer
