// Tailwind Merge

import { twMerge } from 'tailwind-merge'
import CircularLoading from '@/components/ui/CircularLoading'

type ProgressBarCircularProps = {
  className?: string
  progressPercentage?: number
}

const ProgressBarCircular = ({ className, progressPercentage }: ProgressBarCircularProps) => {
  return (
    <div
      className={twMerge('relative flex flex-col items-center justify-center', className)}
      style={{ width: 70, height: 70, borderRadius: '100%' }}
    >
      <CircularLoading size={70} className='absolute' />
      <span className={'text-lg font-medium'}>{progressPercentage}%</span>
    </div>
  )
}

export default ProgressBarCircular
