import CatLottie from '@/components/atom/LottieAnimations/CatLottie.tsx'
import { twMerge } from 'tailwind-merge'

type ScreenLoadingProps = {
  className?: string
}

const ScreenLoading = ({ className }: ScreenLoadingProps) => {
  return (
    <section className={twMerge('flex h-full w-full items-center justify-center', className)}>
      <div className='flex flex-col items-center justify-center'>
        <div className={'ml-9'}>
          <CatLottie height={200} width={200} />
        </div>
        <span style={{ marginTop: '-2rem' }} className={'text-lg font-semibold'}>
          Načítáme pro tebe obsah🕵️
        </span>
      </div>
    </section>
  )
}

export default ScreenLoading
