// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { TPublicUser } from '@/types/user.ts'
import { useGetUsersOffers } from '@/api/queries/offer.queries.ts'

import OffersFeedItem from '@/components/molecule/OffersFeedItem'
import InfiniteScrollList from '@/components/molecule/InfiniteScrollList'

type OffersGalleryProps = {
  className?: string
  user?: TPublicUser
  type: 'public' | 'owner'
}

const OffersFeed = ({ user, className, type }: OffersGalleryProps) => {
  const { data, fetchNextPage, hasNextPage, reset, isPending } = useGetUsersOffers(user?.id)

  const offer = data?.pages[0].data[0]

  if (!offer) return null

  return (
    <div className={twMerge('container mx-auto py-8', className)}>
      <div className='flex flex-col  gap-16 '>
        <InfiniteScrollList
          dataLength={data?.pages.reduce((acc, order) => acc + order.data.length, 0)}
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          refreshFunction={() => reset()}
          isLoading={isPending}
        >
          {data?.pages?.map(page =>
            page.data.map(offer => <OffersFeedItem offer={offer} key={offer.id} type={type} />),
          )}
        </InfiniteScrollList>
      </div>
    </div>
  )
}

export default OffersFeed
