const QUERY_KEYS = Object.freeze({
  OFFER_PRIVATE_DETAIL: 'offer-private-detail',
  OFFER_PUBLIC_DETAIL: 'offer-public-detail',
  ORDERS: 'orders',
  ORDERS_PRIVATE: 'orders-private',
  OFFER_ORDERS: 'offer-orders',
  MY_PROFILE: 'my-profile',
  MY_USER: 'my-user',
  MY_OFFERS: 'my-offers',
  REGISTRATION_REQUEST: 'registration-request',
  PASSWORD_RESET: 'password-reset',
  STRIPE_ONBOARDING: 'stripe-onboarding',
  USER: 'user',
  USERS_OFFERS: 'users-offers',
})

export default QUERY_KEYS
