import { axiosClient } from '@/lib/axios/axios.ts'
import API_ROUTES from '@/constants/apiRoutes.ts'
import { useState } from 'react'
import { TFile } from '@/types/file.ts'

const useUploadFiles = () => {
  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  async function uploadFiles(files: FileList) {
    const formData = new FormData()
    Array.from(files).map(file => formData.append(`files`, file))
    setIsLoading(true)
    const res = await axiosClient.post<TFile[]>(API_ROUTES.FILE, formData, {
      onUploadProgress: progressEvent => {
        const percentCompleted = progressEvent.total
          ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
          : 0
        setProgress(percentCompleted)
      },
    })
    setIsLoading(false)
    return res
  }

  return { uploadFiles, progress, isLoading }
}

export default useUploadFiles
