import Modal from '@/components/modal/Modal'
import PasswordResetRequestForm from '@/components/form/PasswordResetRequestForm'

type RegistrationRequestModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
}

const PasswordResetRequestModal = ({ onClose, isOpened }: RegistrationRequestModalProps) => {
  return (
    <Modal onClose={onClose} isOpened={isOpened} className={' w-[40rem] px-6 py-8 '}>
      <PasswordResetRequestForm onSuccessfulSubmit={onClose} />
    </Modal>
  )
}

export default PasswordResetRequestModal
