// Tailwind Merge

import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'

type AvatarProps = {
  className?: string
  username?: string
  size?: 'large' | 'small' | 'medium'
  href?: string
}

const Avatar = ({ className, size, href, username }: AvatarProps) => {
  const avatarClassName = twMerge(
    'text-md flex h-12 w-12 select-none items-center justify-center rounded-full bg-primary-100  font-semibold text-primary-900',
    size === 'large' && 'h-32 w-32 text-3xl',
    size === 'medium' && 'min-h-24 min-w-24 text-lg',
    className,
  )

  const avatarContent = `${username ? username?.slice(0, 3).toUpperCase() : ''}`

  if (href)
    return (
      <Link to={href} className={avatarClassName}>
        {avatarContent}
      </Link>
    )

  return <div className={avatarClassName}>{avatarContent}</div>
}

export default Avatar
