import { twMerge } from 'tailwind-merge'
import { IconX } from '@tabler/icons-react'
import { useRef } from 'react'
import useClickOutside from '@/hooks/useClickOutside.ts'
import Button from '@/components/ui/Button'

type ModalProps = {
  className?: string
  children?: React.ReactNode
  onClose?: () => void
  isOpened?: boolean
}

const Modal = ({ className, children, onClose, isOpened }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  useClickOutside(modalRef, onClose)

  if (!isOpened) return null

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
      <div className={'fixed inset-0 bg-black opacity-50'} />
      <div
        className={twMerge(
          'relative z-10 m-2 overflow-hidden rounded-xl bg-white p-12 px-6 py-14 pb-8',
          className,
        )}
        style={{ maxWidth: '600px' }}
        ref={modalRef}
      >
        <Button
          icon={IconX}
          size={'small'}
          variant={'plain'}
          className={'absolute left-1 top-1 text-gray-600'}
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  )
}

export default Modal
