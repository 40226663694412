// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconLink, IconLock, IconUser, IconWallet } from '@tabler/icons-react'
import NavbarItem from '@/components/atom/NavbarItem'
import { ROUTES } from '@/constants/routes.tsx'
import Button from '@/components/ui/Button'
import { axiosClient } from '@/lib/axios/axios.ts'
import ApiRoutes from '@/constants/apiRoutes.ts'
import { TStripeLinkUrl } from '@/types/stripeLinkUrl.ts'
import { useGetMyUser } from '@/api/queries/user.queries.ts'
import { EBusinessAccountStatus } from '@/types/enum/businessAccountStatus.ts'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import toast from 'react-hot-toast'
import ScreenLoading from '@/components/atom/ScreenLoading'

type BottomNavbarProps = {
  className?: string
}

const BottomNavbar = ({ className }: BottomNavbarProps) => {
  const { data: myUserData } = useGetMyUser()
  const navigate = useNavigate()
  const [isStripeLoading, setIsStripeLoading] = useState(false)

  const _onWalletNavigation = async () => {
    if (
      myUserData?.data?.businessProfile?.businessAccountStatus !== EBusinessAccountStatus.ACTIVE
    ) {
      navigate(ROUTES.BUSINESS_REQUIRED)
      return
    }
    const newWindow = window.open()

    try {
      setIsStripeLoading(true)
      const link = await axiosClient.get<TStripeLinkUrl>(ApiRoutes.STRIPE_LOGIN)
      if (newWindow) newWindow.location = link.data.url
      setIsStripeLoading(false)
    } catch (e) {
      setIsStripeLoading(false)
      newWindow?.close()
      toast.error('Chyba služby stripe😢, zkus to prosím později.')
    }
  }

  if (isStripeLoading) return <ScreenLoading />

  return (
    <nav className={twMerge(className)}>
      <ul className={'flex items-center justify-around rounded-2xl bg-surface-100 px-2 py-4'}>
        <li>
          <Button
            icon={IconLock}
            label={'Zamknout'}
            className={'rounded-3xl'}
            href={ROUTES.DASHBOARD}
          />
        </li>
        <NavbarItem
          icon={IconWallet}
          label={'Peněženka'}
          className={'flex-1'}
          onClick={_onWalletNavigation}
        />
        <NavbarItem icon={IconLink} label={'Linky'} className={'flex-1'} href={ROUTES.OFFERS} />
        <NavbarItem
          icon={IconUser}
          label={'Profil'}
          className={'flex-1'}
          href={`${ROUTES.USER_DETAIL}/${myUserData?.data?.id}`}
        />
      </ul>
    </nav>
  )
}

export default BottomNavbar
