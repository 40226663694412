// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateRegistrationRequest } from '@/api/queries/registrationRequest.queries.ts'
import ModalHeading from '@/components/atom/ModalHeading'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import { emailSchema, invitationCodeSchema } from '@/utils/zod.ts'
import { useState } from 'react'
import InfoMessage from '@/components/ui/InfoMessage'
import { useCreatePreRegistration } from '@/api/queries/preRegistration.queries.tsx'

type RegistrationRequestFormProps = {
  className?: string
  onSuccessfulSubmit?: () => void
}

const registrationRequestSchema = z.object({
  email: emailSchema,
  invitationCode: invitationCodeSchema,
})

export type TRegistrationRequestSchema = z.infer<typeof registrationRequestSchema>

const RegistrationRequestForm = ({
  className,
  onSuccessfulSubmit,
}: RegistrationRequestFormProps) => {
  const { mutateAsync: createRegistrationRequest, isPending: isRegistrationRequestPending } =
    useCreateRegistrationRequest()
  const { mutateAsync: createPreRegistration, isPending: isPreRegistrationPending } =
    useCreatePreRegistration()
  const [isInvitationCodeInputOpened, setIsInvitationCodeInputOpened] = useState(false)

  const {
    register,
    formState: { isValid, isSubmitSuccessful },
    handleSubmit,
  } = useForm<TRegistrationRequestSchema>({
    resolver: zodResolver(registrationRequestSchema),
    mode: 'onChange',
  })

  const _onSubmit = handleSubmit(async data => {
    if (data?.invitationCode?.length) {
      // @ts-expect-error - invitation code checked in condition
      await createRegistrationRequest(data)
    } else {
      await createPreRegistration({ email: data.email })
    }
  })

  if (isSubmitSuccessful) {
    return (
      <div className={'flex flex-col gap-8 px-10'}>
        <ModalHeading
          heading={'Potvrzovací email odeslán'}
          subHeading={'Na tvojí adresu byl odeslán potvrzovací email, zkontroluj si schránku'}
        />
        <Button
          label={'Hotovo!🥳'}
          className={'w-full'}
          onClick={() => onSuccessfulSubmit && onSuccessfulSubmit()}
        />
      </div>
    )
  }

  return (
    <div>
      <ModalHeading heading={'Předběžná registrace'} />

      <InfoMessage
        className={'mb-5 mt-2'}
        message={
          '❗🚨Aktuálně probíhá uzavřené testování, pro předběznou registraci nám zanech svůj email a my ti dáme vědět až příjdeš na řadu🚨❗'
        }
      />

      <form className={twMerge('flex flex-col gap-4', className)} onSubmit={_onSubmit}>
        <Input
          id={'email'}
          required
          label={'Email'}
          type={'email'}
          placeholder={'cestmir.mazl@example.cz'}
          register={register}
          name={'email'}
        />
        {!isInvitationCodeInputOpened && (
          <button
            className={'text-right text-primary-500'}
            type={'button'}
            onClick={() => setIsInvitationCodeInputOpened(true)}
          >
            Máš zvací kód?
          </button>
        )}

        {isInvitationCodeInputOpened && (
          <Input
            id={'invitationCode'}
            label={'Zvací kód'}
            type={'text'}
            placeholder={'XXXXX'}
            register={register}
            name={'invitationCode'}
          />
        )}

        <Button
          label={'Registrovat 🥳'}
          className={'w-full'}
          disabled={!isValid}
          isLoading={isPreRegistrationPending || isRegistrationRequestPending}
        />
      </form>
      <p className='subtitle mt-5 text-center text-sm'>
        Registrací souhlasíte s{' '}
        <a className='underline' href='/docs/terms.pdf'>
          Podmínkami užití a Zásadami ochrany osobních údajů, včetně Používání souborů cookie.
        </a>
      </p>
    </div>
  )
}

export default RegistrationRequestForm
