import Button from '@/components/ui/Button'
import { twMerge } from 'tailwind-merge'
import React from 'react'

export type ToggleGroupItemProps = {
  label: string
  isActive?: boolean
  value: string | boolean
  onClick?: () => void
  icon?: React.ElementType
}
const ToggleGroupItem = ({ label, isActive, onClick, icon }: ToggleGroupItemProps) => {
  return (
    <Button
      label={label}
      variant={isActive ? 'secondary' : 'neutral'}
      className={twMerge('flex-1', isActive && 'ring-2 ring-primary-500')}
      type={'button'}
      onClick={onClick}
      icon={icon}
    />
  )
}

export default ToggleGroupItem
