// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSignUp } from '@/api/auth.ts'
import toast from 'react-hot-toast'
import { AxiosError, HttpStatusCode } from 'axios'
import { TRegistrationRequest } from '@/types/registrationRequest.ts'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import { passwordSchema, usernameSchema } from '@/utils/zod.ts'
import VisibilityToggleGroup from '@/components/form/VisibilityToggleGroup'
import CustomTooltip from '@/components/ui/CustomTooltip'

type RegistrationFormProps = {
  className?: string
  registrationRequest: TRegistrationRequest
}

const registrationSchema = z
  .object({
    username: usernameSchema,
    password: passwordSchema,
    passwordRepeat: z.string(),
    isPublic: z.boolean(),
  })
  .refine(data => data.password === data.passwordRepeat, {
    message: 'Hesla se musí shodovat', // Custom error message
    path: ['passwordRepeat'], // Path of the field to which the error message is associated
  })

export type TRegistrationSchema = z.infer<typeof registrationSchema>

const RegistrationForm = ({ className, registrationRequest }: RegistrationFormProps) => {
  const { signUp } = useSignUp()

  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    control,
  } = useForm<TRegistrationSchema>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {
      isPublic: false,
    },
  })

  const _onSubmit = handleSubmit(async data => {
    try {
      await signUp({
        username: data.username,
        password: data.password,
        isPublic: data.isPublic,
        registrationRequestId: registrationRequest.id,
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Gone) {
          toast.error('Požadavek na registraci vypršel, začněte prosím registraci znovu')
          return
        }
        if (e.response?.status === HttpStatusCode.Conflict) {
          toast.error('Tento název profilu již používá někdo jiný')
          return
        }
      }
      toast.error('Něco se pokazilo, vygenerujte si prosím nový link')
    }
  })

  return (
    <form
      className={twMerge('flex w-full flex-col gap-4 overflow-hidden', className)}
      onSubmit={_onSubmit}
    >
      <Input id={'email'} label={'Email'} value={registrationRequest.email} disabled />
      <Input
        required
        label={'Název profilu'}
        placeholder={'Cestmirek09'}
        id={'username'}
        register={register}
        name={'username'}
        error={errors['username']}
      />

      <Input
        required
        label={'Heslo'}
        type={'password'}
        id={'password'}
        name={'password'}
        register={register}
        error={errors['password']}
      />
      <Input
        required
        label={'Heslo znovu'}
        type={'password'}
        register={register}
        name={'passwordRepeat'}
        id={'passwordRepeat'}
        error={errors['passwordRepeat']}
      />
      <VisibilityToggleGroup
        control={control}
        name={'isPublic'}
        label={
          <>
            <span className={'flex items-center gap-2'}>
              Viditelnost profilu
              <CustomTooltip
                message={
                  'Veřejná viditelnost tvého profilu, Veřejný znamená, že tvůj profil bude viditelný pro všechny. U každého linku si však můžeš zvlášť nastavit, jestli chceš aby byl na tvém profilu dohledatelný nebo ne.'
                }
              />
            </span>
          </>
        }
      />
      <Button
        label={'Vytvořit účet🥳'}
        className={'mb-2 w-full'}
        size={'large'}
        isLoading={isSubmitting}
      />
    </form>
  )
}

export default RegistrationForm
