import { axiosClient } from '@/lib/axios/axios.ts'
import API_ROUTES from '@/constants/apiRoutes.ts'
import { EBusinessType } from '@/types/enum/BusinessType.ts'
import { ECompanyVatStatus } from '@/types/enum/CompanyVatStatus.ts'
import { TPublicUser, TUser } from '@/types/user.ts'
import { TStripeLinkUrl } from '@/types/stripeLinkUrl.ts'

interface UpdateUserInterface {
  isPublic?: boolean
  username?: string
  bio?: string
}
export const updateMyUser = async (data: UpdateUserInterface) => {
  return axiosClient.put(API_ROUTES.MY_USER, data)
}

interface CreateBusinessProfileInterface {
  businessType: EBusinessType
  companyName?: string
  companyRegistrationId?: string
  vatStatus?: ECompanyVatStatus
  vatId?: string
  firstName: string
  lastName: string
  streetNumber: string
  city: string
  phoneNumber: string
  postalCode: string
  street: string
}
export const createBusinessProfile = async (data: CreateBusinessProfileInterface) => {
  return axiosClient.post<TUser>(API_ROUTES.BUSINESS_PROFILE, data)
}

export const getStripeOnboarding = async () => {
  return axiosClient.get<TStripeLinkUrl>(API_ROUTES.STRIPE_ONBOARDING)
}

export const getMyUser = async () => {
  return axiosClient.get<TUser>(API_ROUTES.MY_USER)
}

export const getUserById = async (userId: string) => {
  return axiosClient.get<TPublicUser>(`${API_ROUTES.USER}/${userId}`)
}
