import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createRegistrationRequest,
  getRegistrationRequestById,
} from '@/api/requests/registrationRequest.requests.ts'
import QUERY_KEYS from '@/constants/queryKeys.ts'
import { useEnhancedQuery } from '../api'
import toast from 'react-hot-toast'
import { AxiosError, HttpStatusCode } from 'axios'

export const useCreateRegistrationRequest = () => {
  return useMutation({
    mutationFn: createRegistrationRequest,
    onError: e => {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Conflict) toast.error('Email byl již použit')
        else if (e.response?.status === HttpStatusCode.Unauthorized)
          toast.error('Zadaný zvací kód není validní.')

        throw e
      }

      toast.error('Něco se pokazilo, zkus to prosím později')
      throw e
    },
  })
}

export const useGetRegistrationRequest = (id?: string) => {
  const queryClient = useQueryClient()

  return {
    ...useEnhancedQuery({
      queryKey: [QUERY_KEYS.REGISTRATION_REQUEST, id],
      queryFn: () => getRegistrationRequestById(id as string),
      enabled: !!id,
    }),
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.REGISTRATION_REQUEST, id] }),
  }
}
