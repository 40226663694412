// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { CSSProperties, useState } from 'react'
import { useCreateOrder } from '@/api/queries/order.queries.ts'
import Button from '@/components/ui/Button'
import { createOrderPayment } from '@/api/requests/order.requests.ts'
import { formatPrice } from '@/utils/price'

type PayCardProps = {
  className?: string
  price: number
  offerId?: string
  style?: CSSProperties
}

const PayCard = ({ className, style, price, offerId }: PayCardProps) => {
  const { mutateAsync: createOrder } = useCreateOrder()
  const [isLoading, setIsLoading] = useState(false)
  const _onPayClick = async () => {
    if (!offerId) return
    setIsLoading(true)
    const order = await createOrder({ offerId })
    const payment = await createOrderPayment(order.data.id)
    setIsLoading(false)
    window.location.href = payment.data.paymentUrl
  }

  return (
    <div
      className={twMerge(
        'flex w-full items-center justify-between rounded-3xl bg-surface-50 px-6 py-4 drop-shadow-lg',
        className,
      )}
      style={style}
    >
      <div className={'flex flex-col'}>
        <span className='text-2xl font-bold'>{formatPrice(price)} Kč</span>
        <span className={'text-xs text-surface-500'}>Zabezpečená platba</span>
      </div>
      <Button
        label={'Odemknout🥳'}
        className={'rounded-2xl font-bold '}
        onClick={_onPayClick}
        size={'large'}
        isLoading={isLoading}
      />
    </div>
  )
}

export default PayCard
