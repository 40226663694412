import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'
import { useGetUserById } from '@/api/queries/user.queries.ts'
import { useParams } from 'react-router-dom'
import PageContainer from '@/components/ui/PageContainer'
import PageHeading from '@/components/atom/PageHeading'
import Button from '@/components/ui/Button'
import { IconSettings } from '@tabler/icons-react'
import { ROUTES } from '@/constants/routes.tsx'
import Avatar from '@/components/ui/Avatar'
import OffersFeed from '@/components/organism/OffersFeed'
import { useAuth } from '@/context/authContext.tsx'
import { useMemo } from 'react'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import { twMerge } from 'tailwind-merge'

const UserDetailPage = () => {
  const { username } = useParams()
  const { auth } = useAuth()
  const { data: userData, isLoading, isNotFound } = useGetUserById(username)
  const isMyProfile = useMemo(() => auth?.sub === userData?.data.id, [auth, userData])

  if (isLoading) {
    return <ScreenLoading />
  }

  if (isNotFound)
    return (
      <InfoMessageContent
        heading='Uživatele se nepodařilo najít 😢.'
        message='Uživatele se nepodařilo najít, buťo neexistuje, nebo má skrytý profil.'
      />
    )

  if (!userData) {
    return <GlobalErrorInfoMessageContent />
  }

  const { data: user } = userData

  const _renderStats = () => {
    return (
      <div className={'flex  flex-1 justify-center gap-16'}>
        <div className={'flex flex-col items-center justify-center'}>
          <span className={'font-semibold'}>{user.publicOffersCount}</span>
          <span className={'text-sm'}>Veřejných linků</span>
        </div>
      </div>
    )
  }

  return (
    <PageContainer className={twMerge(!isMyProfile && '-mt-6')}>
      <PageHeading
        title={user.username}
        leftContent={
          <>
            {isMyProfile && (
              <Button
                icon={IconSettings}
                size={'small'}
                variant={'secondary'}
                href={ROUTES.SETTINGS}
              />
            )}
          </>
        }
      />
      <div className={'flex flex-col gap-2'}>
        <div className={'flex  items-center gap-2 '}>
          <Avatar username={user.username} size={'medium'} />
          {_renderStats()}
        </div>
        <div>{user.bio}</div>
        <div className={'w-full border-b'} />
      </div>
      <OffersFeed className={'-mt-8'} user={user} type={isMyProfile ? 'owner' : 'public'} />
    </PageContainer>
  )
}

export default UserDetailPage
