import Modal from '@/components/modal/Modal'
import LoginForm from '@/components/form/LoginForm'
import ModalHeading from '@/components/atom/ModalHeading'

type LoginFormModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
  offerId?: string
  onRegisterChoice?: () => void
  onPasswordResetChoice?: () => void
}

const LoginFormModal = ({
  onClose,
  isOpened,
  onRegisterChoice,
  onPasswordResetChoice,
}: LoginFormModalProps) => {
  return (
    <Modal onClose={onClose} isOpened={isOpened} className={'w-96'}>
      <div className={'flex h-full flex-col justify-center'}>
        <ModalHeading
          heading={'Přihlaště se!👋'}
          subHeading={'Pro přihlášení zadejte vaše údaje'}
          className={'mb-6'}
        />
        <LoginForm onPasswordResetChoice={onPasswordResetChoice} />
        <span className={'mt-6 text-center'}>
          Ještě nemáte účet?{' '}
          <button
            className={'text-center font-semibold text-primary-500'}
            onClick={onRegisterChoice}
          >
            Zaregistrujte se
          </button>
        </span>
      </div>
    </Modal>
  )
}

export default LoginFormModal
