// Tailwind Merge
import { formatPrice } from '@/utils/price'
import { twMerge } from 'tailwind-merge'

type OfferStatsProps = {
  className?: string
  successfulOrdersCount?: number
  revenuesTotal: number
}

const OfferStats = ({
  className,
  successfulOrdersCount,
  revenuesTotal: revenuesTotal,
}: OfferStatsProps) => {
  return (
    <div className={twMerge('flex w-full justify-center', className)}>
      <div className={'flex flex-1 flex-col items-center justify-center'}>
        <span className={'font-semibold'}>{successfulOrdersCount}</span>
        <span className={'text-center text-sm'}>Objednávek</span>
      </div>
      <div className={'border-l-2'} />
      <div className={'flex flex-1 flex-col items-center justify-center'}>
        <span className={'font-semibold'}>{formatPrice(revenuesTotal)} Kč</span>
        <span className={'text-sm'}>Výdělky</span>
      </div>
    </div>
  )
}

export default OfferStats
