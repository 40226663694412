import UploadCard from '@/components/organism/UploadCard'
import { useState } from 'react'
import CreateOfferForm from '@/components/form/CreateOfferForm'
import { useCreateOffer } from '@/api/queries/offer.queries.ts'
import { TCreateOfferSchema } from '@/components/form/CreateOfferForm/CreateOfferForm.tsx'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'
import { TFile } from '@/types/file.ts'
import PageContainer from '@/components/ui/PageContainer'
import { useGetMyUser } from '@/api/queries/user.queries.ts'
import { EBusinessAccountStatus } from '@/types/enum/businessAccountStatus.ts'
import ScreenLoading from '@/components/atom/ScreenLoading'

const LockFilePage = () => {
  const [uploadedFile, setUploadedFile] = useState<TFile | null>(null)
  const { mutateAsync: createOffer } = useCreateOffer()
  const { data: myUserData, isLoading: isMyUserDataLoading } = useGetMyUser()
  const navigate = useNavigate()

  if (isMyUserDataLoading) {
    return <ScreenLoading />
  }

  if (myUserData?.data?.businessProfile?.businessAccountStatus !== EBusinessAccountStatus.ACTIVE)
    return <Navigate to={ROUTES.BUSINESS_REQUIRED} replace />

  const _onSubmit = async (data: TCreateOfferSchema) => {
    if (!uploadedFile) return
    const res = await createOffer({
      fileIds: [uploadedFile.id],
      price: data.price,
      name: data.name,
      orderAccessDurationLimit: data.orderAccessDurationLimit,
      isPublic: data.isPublic,
      caption: data.caption,
    })
    navigate(`${ROUTES.OFFER_SHARE}/${res.data.id}`)
  }

  return (
    <PageContainer>
      <UploadCard
        onFileUpload={setUploadedFile}
        uploadedFile={uploadedFile}
        style={{ flex: '.5' }}
      />
      <CreateOfferForm style={{ flex: '.5' }} onSubmit={_onSubmit} disabled={!uploadedFile} />
    </PageContainer>
  )
}

export default LockFilePage
