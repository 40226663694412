import { useState } from 'react'
import LoginFormModal from '@/components/modal/LoginFormModal'
import RegistrationRequestModal from '@/components/modal/RegistrationRequestModal'
import PasswordResetRequestModal from '@/components/modal/PasswordResetRequestModal/PasswordResetRequestModal.tsx'
import Logo from '@/components/atom/Logo'

const LandingPage = () => {
  const [isLoginOpened, setIsLoginOpened] = useState(false)
  const [isRegistrationOpened, setIsRegistrationOpened] = useState(false)
  const [isPasswordResetModalOpened, setIsPasswordResetModalOpened] = useState(false)

  return (
    <>
      <div className={'flex h-full w-full flex-col items-center gap-10'}>
        <div className={'mt-10 flex  w-full justify-center md:mt-4'}>
          <Logo variant={'dark'} />
        </div>
        <div className={'flex flex-1 flex-col items-center justify-center gap-20'}>
          <div className={'flex flex-col items-center justify-center gap-2'}>
            <h1 className={'text-center text-4xl font-bold text-white'}>
              Nahraj, Sdílej, Vydělávej
            </h1>
            <span className={'max-w-64 text-center text-lg font-medium text-white text-opacity-95'}>
              Nejrychlejší způsob monetizace contentu
            </span>
          </div>
          <span className={'text-xl'}>📸 ➡️ ️💰 ➡️️ 🥳</span>
          <div className={'flex w-56 flex-col gap-4 md:w-64 '}>
            <button
              className={
                'w-full rounded-lg bg-gray-900 bg-opacity-80 px-3 py-4 font-semibold text-white'
              }
              onClick={() => setIsLoginOpened(true)}
            >
              Přihlásit se🥳
            </button>
            <button
              className={
                'w-full rounded-lg bg-white bg-opacity-15 px-3 py-4 font-semibold text-white'
              }
              onClick={() => setIsRegistrationOpened(true)}
            >
              Registrovat se 📝
            </button>
          </div>
        </div>
      </div>
      <LoginFormModal
        isOpened={isLoginOpened}
        onClose={() => setIsLoginOpened(false)}
        onRegisterChoice={() => {
          setIsLoginOpened(false)
          setIsRegistrationOpened(true)
        }}
        onPasswordResetChoice={() => {
          setIsLoginOpened(false)
          setIsPasswordResetModalOpened(true)
        }}
      />
      <RegistrationRequestModal
        isOpened={isRegistrationOpened}
        onClose={() => setIsRegistrationOpened(false)}
      />
      <PasswordResetRequestModal
        isOpened={isPasswordResetModalOpened}
        onClose={() => setIsPasswordResetModalOpened(false)}
      />
    </>
  )
}

export default LandingPage
