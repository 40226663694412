// Tailwind Merge
import FileThumbnail from '@/components/molecule/FilePreviewThumbnail'
import { TFile } from '@/types/file.ts'
import DurationBadge from '@/components/atom/DurationBadge'
import { EFileType } from '@/types/enum/fileType.ts'
import Button from '@/components/ui/Button'
import { IconDownload, IconMaximize } from '@tabler/icons-react'
import { useState } from 'react'
import MediaPlayerModal from '@/components/modal/MediaPlayerModal'

type FileThumbnailWithPlayerProps = {
  file: TFile
  disablePlayer?: boolean
}

const FileThumbnailWithPlayer = ({ file, disablePlayer }: FileThumbnailWithPlayerProps) => {
  const [isMediaModalOpened, setIsMediaModalOpened] = useState(false)

  const _onThumbnailClick = () => {
    if (file.fileType === EFileType.VIDEO) {
      setIsMediaModalOpened(true)
    }
  }

  const _renderRightTopContent = () => {
    return (
      <>
        {file.duration && <DurationBadge duration={file.duration} />}
        {file.fileType === EFileType.PHOTO && (
          <Button
            icon={IconMaximize}
            size={'extra-small'}
            onClick={() => setIsMediaModalOpened(true)}
          />
        )}
        {file.fileType === EFileType.FILE && (
          <Button
            icon={IconDownload}
            size={'extra-small'}
            onClick={() => window.open(file.fileUrl, '_blank')}
          />
        )}
      </>
    )
  }
  return (
    <>
      <FileThumbnail
        className={'w-full overflow-hidden'}
        file={file}
        type={'private'}
        rightTopContent={_renderRightTopContent()}
        allowPlay={!disablePlayer}
        onClick={disablePlayer ? undefined : _onThumbnailClick}
      />
      <MediaPlayerModal
        file={file}
        isOpened={isMediaModalOpened}
        onClose={() => setIsMediaModalOpened(false)}
      />
    </>
  )
}

export default FileThumbnailWithPlayer
