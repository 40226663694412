// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'

type ListItemProps = {
  className?: string
  href: string
  leftContent: React.ReactNode
  rightContent: React.ReactNode
}

const ListItem = ({ className, href, leftContent, rightContent }: ListItemProps) => {
  return (
    <Link to={href} className={className}>
      <li
        className={twMerge(
          'flex cursor-pointer  justify-between  gap-2 border-b border-gray-200 p-3',
        )}
      >
        {leftContent}
        <div className={'flex flex-1 flex-col  justify-center '}>{rightContent}</div>
      </li>
    </Link>
  )
}

export default ListItem
