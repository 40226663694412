// Tailwind Merge
import { EOfferState } from '@/types/enum/offerState.ts'
import { TOffer } from '@/types/offer.ts'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'
import { useMemo } from 'react'
import Badge from '@/components/ui/Badge'
import { offerStateDictionary } from '@/constants/offerStateDictionary.ts'

type OfferStateBadgeProps = {
  offer: TOffer
  size?: 'sm' | 'md'
}

const OfferStateBadge = ({ offer, size }: OfferStateBadgeProps) => {
  const { state: offerState, uploadStatus } = offer

  const _renderLabel = () => {
    if (uploadStatus === EOfferUploadStatus.PROCESSING) return 'Zpracovává se'
    else return offerStateDictionary[offerState]
  }

  const variant = useMemo(() => {
    if (uploadStatus === EOfferUploadStatus.PROCESSING) return 'warning'
    if (offerState === EOfferState.ACTIVE) return 'primary'
    return 'disabled'
  }, [offerState, uploadStatus])

  return (
    <Badge size={size} variant={variant}>
      {_renderLabel()}
    </Badge>
  )
}

export default OfferStateBadge
