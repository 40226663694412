// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import ModalHeading from '@/components/atom/ModalHeading'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'
import { TPasswordReset } from '@/types/passwordReset.ts'
import { useResetPassword } from '@/api/queries/passwordReset.queries.ts'
import toast from 'react-hot-toast'
import { AxiosError, HttpStatusCode } from 'axios'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import { passwordSchema } from '@/utils/zod.ts'

type PasswordResetFormProps = {
  className?: string
  passwordReset: TPasswordReset
}

const passwordResetSchema = z
  .object({
    password: passwordSchema,
    passwordRepeat: z.string(),
  })
  .refine(data => data.password === data.passwordRepeat, {
    message: 'Hesla se musí shodovat', // Custom error message
    path: ['passwordRepeat'], // Path of the field to which the error message is associated
  })

export type TPasswordResetSchema = z.infer<typeof passwordResetSchema>

const PasswordResetForm = ({ className, passwordReset }: PasswordResetFormProps) => {
  const navigate = useNavigate()
  const { mutateAsync: resetPassword } = useResetPassword()
  const {
    formState: { isSubmitting, isSubmitSuccessful, errors },
    handleSubmit,
    register,
  } = useForm<TPasswordResetSchema>({
    resolver: zodResolver(passwordResetSchema),
  })

  if (isSubmitSuccessful) {
    return (
      <div className={'flex flex-col gap-8 px-10'}>
        <ModalHeading subHeading={'Heslo bylo úspěšně obnoveno, nyní se můžeš přihlásit'} />
        <Button
          label={'Vrátit se na přihlášení!🥳'}
          className={'w-full'}
          onClick={() => navigate(ROUTES.LANDING)}
        />
      </div>
    )
  }

  const _onSubmit = handleSubmit(async data => {
    try {
      await resetPassword({
        newPassword: data.password,
        passwordResetId: passwordReset.id,
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === HttpStatusCode.Gone) {
          toast.error('Požadavek na obnovení hesla vypršel, začněte prosím znovu')
          return
        }
      }
      toast.error('Něco se pokazilo, zkuste to prosím později')
      throw e
    }
  })

  return (
    <form className={twMerge('flex w-full flex-col gap-4', className)} onSubmit={_onSubmit}>
      <Input
        required
        id={'password'}
        label={'Heslo'}
        type={'password'}
        register={register}
        name={'password'}
        error={errors['password']}
      />
      <Input
        required
        id={'password'}
        label={'Heslo znovu'}
        type={'password'}
        register={register}
        name={'passwordRepeat'}
        error={errors['passwordRepeat']}
      />
      <Button label={'Obnovit heslo'} className={'w-full'} isLoading={isSubmitting} />
    </form>
  )
}

export default PasswordResetForm
