import { format, parseISO } from 'date-fns'

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

export type TDurationObject = {
  days: number
  hours: number
  minutes: number
}

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === 'string' && ISODateFormat.test(value)
}

export function handleDates(data: unknown) {
  if (isIsoDateString(data)) return parseISO(data)
  if (data === null || data === undefined || typeof data !== 'object') return data

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = parseISO(val)
    else if (typeof val === 'object') handleDates(val)
  }

  return data
}

export function formatDateToString(date: Date): string {
  return format(date, 'd.M.yyyy')
}

export function formatDateTimeToString(date: Date): string {
  return format(date, 'd.M.yyyy H:mm')
}

export function convertDuration(durationInSeconds: number) {
  const secondsInAMinute = 60
  const minutesInAnHour = 60
  const minutesInADay = 60 * 24
  const secondsInADay = 24 * 60 * 60
  const secondsInHour = 60 * 60

  // First, convert the total duration from seconds to minutes
  const totalMinutes = durationInSeconds / secondsInAMinute

  const days = Math.floor(totalMinutes / minutesInADay)
  const hours = Math.floor((totalMinutes % minutesInADay) / minutesInAnHour)
  const minutes = Math.floor(totalMinutes % minutesInAnHour)

  const seconds = durationInSeconds - days * secondsInADay - hours * secondsInHour - minutes * 60

  return { days, hours, minutes, seconds }
}

export function durationObjectToString(duration: TDurationObject) {
  return `${duration.days}d ${duration.hours}h ${duration.minutes}m`
}

export function durationToString(durationInSeconds?: number) {
  if (!durationInSeconds) return ''
  return durationObjectToString(convertDuration(durationInSeconds))
}

// HH:MM:SS or MM:SS
export function getVideoDuration(durationInSeconds: number) {
  const durationObject = convertDuration(durationInSeconds)
  const { hours, minutes, seconds } = durationObject
  const hoursString = !!hours && `${hours < 9 ? '0' : ''}${hours}`
  const minutesString = minutes ? `${minutes < 9 ? '0' : ''}${minutes}` : '00'
  const secondsString = seconds ? `${seconds < 9 ? '0' : ''}${seconds}` : '00'
  return `${hoursString ? hoursString : ''}${hoursString ? ':' : ''}${minutesString}:${secondsString}`
}
