// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import useLogin from '@/api/auth.ts'
import { AxiosError } from 'axios'
import STATUS_CODES from '@/constants/statusCodes.ts'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import { emailSchema } from '@/utils/zod.ts'

type LoginFormProps = {
  className?: string
  onPasswordResetChoice?: () => void
}

const loginSchema = z.object({
  email: emailSchema,
  password: z.string().min(1, 'Zadej heslo'),
})

export type TLoginSchema = z.infer<typeof loginSchema>

const LoginForm = ({ className, onPasswordResetChoice }: LoginFormProps) => {
  const navigate = useNavigate()
  const { login } = useLogin()
  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
  } = useForm<TLoginSchema>({
    resolver: zodResolver(loginSchema),
  })

  const onSubmit = handleSubmit(async data => {
    try {
      await login(data.email, data.password)
      navigate(ROUTES.DASHBOARD)
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === STATUS_CODES.UNAUTHORIZED) {
        toast.error('Nesprávné přihlašovací údaje')
        return
      }
      toast.error('Něco se pokazilo')
    }
  })

  return (
    <form className={twMerge('flex flex-col gap-7', className)} onSubmit={onSubmit}>
      <Input
        id={'email'}
        label={'Email'}
        error={errors['email']}
        required
        register={register}
        name={'email'}
      />
      <div className={'flex flex-col gap-1'}>
        <Input
          id={'password'}
          label={'Heslo'}
          type={'password'}
          error={errors['password']}
          required
          register={register}
          name={'password'}
        />
        <button
          className={'text-right text-primary-500'}
          onClick={() => onPasswordResetChoice && onPasswordResetChoice()}
          type={'button'}
        >
          Zapomněli jste heslo?
        </button>
      </div>
      <Button
        className={'mt-6 w-full'}
        label={'Přihlásit se 🥳'}
        size={'large'}
        isLoading={isSubmitting}
      />
    </form>
  )
}

export default LoginForm
