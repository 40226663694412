// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import React, { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'
import CircularLoading from '@/components/ui/CircularLoading'

type ButtonButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

type ButtonSharedProps = {
  disabled?: boolean
  className?: string
  label?: string | ReactNode
  size?: 'small' | 'large' | 'extra-small'
  icon?: React.ElementType
  isLoading?: boolean
  href?: string
  variant?: 'primary' | 'secondary' | 'plain' | 'neutral'
  form?: string
}

type ButtonProps = ButtonSharedProps & ButtonButtonProps

const Button = ({
  className,
  label,
  size,
  icon,
  isLoading,
  disabled: disabledProps,
  variant,
  ...rest
}: ButtonProps) => {
  const IconComponent = icon
  const iconSize = useMemo(() => {
    if (size === 'extra-small') return 20
    if (size === 'large') return 20
    if (size === 'small') return 20
    return 20
  }, [size])
  const disabled = disabledProps || isLoading

  const loadingSize = useMemo(() => {
    if (size === 'extra-small') return 20
    if (size === 'small') return 20
    if (size === 'large') return 25
    return 20
  }, [size])

  const iconStroke = useMemo(() => {
    if (size === 'extra-small') return 3
    if (size === 'small') return 2
    if (size === 'large') return 2
  }, [size])

  const buttonClassName = twMerge(
    'flex w-fit cursor-pointer select-none items-center justify-center gap-2 rounded-md bg-primary-400 p-3 text-sm font-semibold text-white transition-colors focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
    !disabled && 'hover:bg-primary-500',
    disabled && 'cursor-not-allowed bg-primary-300',
    size === 'large' && 'text-lg font-medium',
    size === 'extra-small' && 'p-1 text-xs',
    size === 'small' && 'text-md p-2',
    variant === 'secondary' && 'bg-primary-100 text-primary-400 hover:text-white',
    disabled &&
      variant === 'secondary' &&
      'hover:text-g bg-gray-100 text-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0',
    variant === 'plain' &&
      'bg-transparent p-1 text-primary-400 ring-0 hover:bg-transparent hover:text-primary-500 focus:ring-0 focus:ring-primary-500 focus:ring-offset-0 focus:ring-offset-primary-500',
    variant === 'neutral' && 'bg-neutral-200 text-neutral-400 hover:bg-neutral-200',
    className,
  )

  const buttonContent = (
    <>
      {isLoading && <CircularLoading color={'neutral'} size={loadingSize} />}
      {IconComponent && <IconComponent stroke={iconStroke} width={iconSize} />}
      {label}
    </>
  )

  if (rest.href)
    return (
      <>
        {disabled ? (
          <span className={buttonClassName}>{buttonContent}</span>
        ) : (
          <Link to={rest.href} className={buttonClassName}>
            {buttonContent}
          </Link>
        )}
      </>
    )

  return (
    <button className={buttonClassName} {...rest} disabled={disabled}>
      {buttonContent}
    </button>
  )
}

export default Button
