// @ts-expect-error - svgr
import LogoIconDark from '../../../icons/logo-dark.svg?react'
// @ts-expect-error - svgr
import LogoIconLight from '../../../icons/logo-light.svg?react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/constants/routes.tsx'

type LogoProps = {
  variant?: 'light' | 'dark'
  size?: 'md' | 'sm' | 'lg'
}

const SIZES = {
  sm: 1,
  lg: 2,
  md: 1.5,
}

const Logo = ({ variant, size }: LogoProps) => {
  return (
    <Link to={ROUTES.DASHBOARD}>
      <div>
        {variant === 'dark' ? (
          <LogoIconDark
            style={{ maxWidth: 128 * SIZES[size || 'md'], maxHeight: 25 * SIZES[size || 'md'] }}
          />
        ) : (
          <LogoIconLight
            style={{ maxWidth: 128 * SIZES[size || 'md'], maxHeight: 25 * SIZES[size || 'md'] }}
          />
        )}
      </div>
    </Link>
  )
}

export default Logo
