import React, { createContext, useState } from 'react'

export type TAuth = {
  email: string
  username: string
  sub: string
}

const AuthContext = createContext<{
  auth?: TAuth | null
  updateAuth: (auth?: TAuth) => void
  isAuthenticated: boolean
}>({} as never)

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState<TAuth | undefined>(undefined)
  return (
    <AuthContext.Provider value={{ updateAuth: setAuth, auth, isAuthenticated: !!auth }}>
      {children}
    </AuthContext.Provider>
  )
}
