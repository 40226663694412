import Modal from '@/components/modal/Modal'
import RegistrationRequestForm from '@/components/form/RegistrationRequestForm'

type RegistrationRequestModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
}

const RegistrationRequestModal = ({ onClose, isOpened }: RegistrationRequestModalProps) => {
  return (
    <Modal onClose={onClose} isOpened={isOpened} className={' w-[40rem] px-6 py-8 '}>
      <RegistrationRequestForm onSuccessfulSubmit={onClose} />
    </Modal>
  )
}

export default RegistrationRequestModal
