// React Router DOM
import { Outlet } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

const ClearLayout = () => {
  return (
    <>
      <body
        className={twMerge('flex h-screen w-full  justify-center bg-gradient ')}
      >
        <div
          className={'flex  w-full flex-col  justify-between pt-3 '}
        >
          <div className={'flex-1 overflow-scroll px-6 py-4'}>
            <Outlet />
          </div>
        </div>
      </body>
    </>
  )
}

export default ClearLayout
