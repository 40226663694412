import { axiosClient } from '@/lib/axios/axios.ts'
import { TOffer } from '@/types/offer.ts'
import API_ROUTES, { getApiRoute } from '@/constants/apiRoutes.ts'
import { TRegistrationRequest } from '@/types/registrationRequest.ts'

type TCreateRegistrationRequest = {
  email: string
  invitationCode: string
}
export const createRegistrationRequest = async (data: TCreateRegistrationRequest) => {
  return axiosClient.post<TOffer>(API_ROUTES.REGISTRATION_REQUEST, data)
}

export const getRegistrationRequestById = async (id: string) => {
  return axiosClient.get<TRegistrationRequest>(
    getApiRoute(API_ROUTES.REGISTRATION_REQUEST_DETAIL, { registrationRequestId: id }),
  )
}
