const API_ROUTES = Object.freeze({
  FILE: '/file',
  OFFER: '/offer',
  OFFER_PRIVATE_DETAIL: 'offer/private/detail',
  OFFER_PUBLIC_DETAIL: 'offer/public/detail',
  ORDER: 'order',
  ORDER_PRIVATE: 'order/private',
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  PROFILE: '/auth/profile',
  LOGOUT: '/auth/logout',
  MY_OFFERS: '/offer/my',
  OFFER_STATS: '/offer/<offerId>/stats',
  DEACTIVATE_OFFER: '/offer/<offerId>/deactivate',
  ACTIVATE_OFFER: '/offer/<offerId>/activate',
  REGISTRATION_REQUEST: '/registration-request',
  PRE_REGISTRATION: '/pre-registrations',
  REGISTRATION: '/auth/register',
  REGISTRATION_REQUEST_DETAIL: '/registration-request/<registrationRequestId>',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_DETAIL: '/password-reset/<passwordResetId>',
  PASSWORD_RESET_ACTION: '/password-reset/reset',
  USER: '/user',
  MY_USER: '/user/my',
  BUSINESS_PROFILE: '/user/my/business-profile',
  STRIPE_ONBOARDING: '/user/my/business-profile/stripe/onboarding',
  STRIPE_LOGIN: '/user/my/business-profile/stripe/login',
  ORDER_PAYMENT: '/order/<orderId>/payment',
})

interface RouteParams {
  [key: string]: number | string | undefined
}

export function getApiRoute(route: string, params: RouteParams = {}): string {
  let updatedRoute = route

  for (const param in params) {
    if (Object.prototype.hasOwnProperty.call(params, param) && params[param] !== undefined) {
      const placeholder = `<${param}>`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      updatedRoute = updatedRoute.replace(placeholder, params[param].toString())
    }
  }

  return updatedRoute
}

export default API_ROUTES
