import { MutableRefObject, useEffect, useMemo } from 'react'

type AnyEvent = MouseEvent | TouchEvent

// in case of array, handler is invoked only if click is recorded outside all elements
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: MutableRefObject<T | undefined | null> | MutableRefObject<T | undefined | null>[],
  handler?: () => void,
): void {
  const refArray = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs])

  useEffect(() => {
    if (!handler) {
      return
    }

    const listener = (event: AnyEvent) => {
      const isClickOutside = refArray.every(ref => {
        const el = ref?.current
        return el && !el.contains(event.target as Node)
      })

      if (isClickOutside) {
        handler()
        event.stopPropagation()
      }
    }

    document.addEventListener(`mousedown`, listener)
    document.addEventListener(`touchstart`, listener)

    return () => {
      document.removeEventListener(`mousedown`, listener)
      document.removeEventListener(`touchstart`, listener)
    }
  }, [refArray, handler])
}

export default useOnClickOutside
