// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { PlayIcon } from '@heroicons/react/20/solid'
import { getVideoDuration } from '@/utils/date.ts'

type DurationBadgeProps = {
  className?: string
  duration: number
}

const DurationBadge = ({ className, duration }: DurationBadgeProps) => {
  return (
    <div
      className={twMerge(
        'flex items-center justify-center gap-2 rounded-xl bg-black bg-opacity-45 px-4 py-3 text-white',
        className,
      )}
    >
      <PlayIcon width={25} />
      <span className={'text-xl font-bold '}>{getVideoDuration(duration)}</span>
    </div>
  )
}

export default DurationBadge
