import { axiosClient } from '@/lib/axios/axios.ts'
import API_ROUTES, { getApiRoute } from '@/constants/apiRoutes.ts'
import { TPaginatedData, TPaginationParams } from '@/types/pagination.ts'
import { TOrder } from '@/types/order.ts'
import { TPayment } from '@/types/payment.ts'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus'

interface TCreateOrderApiInput {
  offerId: string
}
export const createOrder = async (data: TCreateOrderApiInput) => {
  return axiosClient.post<TOrder>(`${API_ROUTES.ORDER}`, data)
}

export const getOrderById = async (orderId: string) => {
  return axiosClient.get<TOrder>(`${API_ROUTES.ORDER}/${orderId}`)
}

export const getOrderPrivateById = async (orderId: string) => {
  return axiosClient.get<TOrder>(`${API_ROUTES.ORDER_PRIVATE}/${orderId}`)
}

export interface IGetOrdersApiParams {
  paginationParams: TPaginationParams
  offerId?: string
  orderPaymentStatus?: EOrderPaymentStatus
}
export const getOrdersByOfferId = async (data: IGetOrdersApiParams) => {
  const { offerId, paginationParams, orderPaymentStatus } = data
  return axiosClient.get<TPaginatedData<TOrder>>(`${API_ROUTES.OFFER}/${offerId}/orders`, {
    params: { ...paginationParams, orderPaymentStatus },
  })
}

export const createOrderPayment = async (orderId: string) => {
  return axiosClient.post<TPayment>(getApiRoute(API_ROUTES.ORDER_PAYMENT, { orderId }))
}
