// React
import { useRef } from 'react'

// Tailwind Merge
import { twMerge } from 'tailwind-merge'

// Types
import { type THeroIcon } from '@/types/heroicons.ts'

// React Hook Form
import { FieldValues, UseFormRegister, RegisterOptions, FieldError, Path } from 'react-hook-form'

type TextAreaProps<T extends FieldValues> = {
  className?: string
  inputClassName?: string
  labelClassName?: string
  id: string
  name?: Path<T>
  placeholder?: string
  disabled?: boolean
  register?: UseFormRegister<T>
  registerOptions?: RegisterOptions
  error?: FieldError
  label?: string
  required?: boolean
  onEnterKeyDown?: (value: string) => void
  value?: string | number | null
  icon?: {
    name: THeroIcon
    onClick?: (value: string) => void
  }
  maxCharacters?: number
  rows?: number
} & React.ComponentProps<'textarea'>

const TextArea = <T extends FieldValues>({
  className,
  inputClassName,
  labelClassName,
  id,
  name,
  register,
  registerOptions,
  error,
  placeholder,
  label,
  required = false,
  onEnterKeyDown,
  icon,
  value,
  disabled,
  maxCharacters,
  ...rest
}: TextAreaProps<T>) => {
  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  const nameProps = {
    ...(register && name
      ? { ...register(name, registerOptions) }
      : {
          name: name,
        }),
  }

  const iconProps = {
    width: 20,
    height: 20,
  }

  const iconClassNames = 'absolute right-4 top-[calc(50%_-_0.625rem)] cursor-pointer text-gray-400'

  const getIcon = () => {
    if (icon) {
      const IconTag = icon.name
      return <IconTag {...iconProps} />
    } else {
      return <></>
    }
  }

  const _renderCharCounter = () => {
    if ((typeof value === 'string' || typeof value === 'undefined') && maxCharacters)
      return (
        <span
          className={twMerge(
            'absolute bottom-4 right-4',
            value && value.length >= maxCharacters && 'text-red-500',
          )}
        >
          {value ? value.length : 0}/{maxCharacters}
        </span>
      )

    return null
  }

  return (
    <div className={twMerge('relative flex flex-col gap-1', className)}>
      {label && (
        <label className={twMerge('text-sm', labelClassName)} htmlFor={id}>
          <span>{label}</span>
          {required && <span className='text-red-500'>{`*`}</span>}
        </label>
      )}

      <div className='relative'>
        <textarea
          rows={4}
          className={twMerge(
            'peer w-full resize-none rounded-xl border border-gray-300 px-4 py-4 placeholder:text-gray-500',
            icon?.name && 'pr-9',
            !error && 'focus:outline-green-500',
            disabled && `cursor-not-allowed bg-gray-100 text-gray-400`,
            inputClassName,
          )}
          id={id}
          placeholder={placeholder}
          onKeyDown={e => {
            if (e.key === 'Enter' && onEnterKeyDown) {
              onEnterKeyDown(e.currentTarget.value)
            }
          }}
          value={value || undefined}
          ref={inputRef}
          disabled={disabled}
          {...nameProps}
          {...rest}
        />
        {icon && (
          <div
            className={twMerge(iconClassNames, !error && 'peer-focus:text-green-500')}
            onClick={() => icon.onClick && icon.onClick(inputRef.current?.value || '')}
          >
            {getIcon()}
          </div>
        )}
        {maxCharacters && _renderCharCounter()}
      </div>
      {error && <div className='text-xs text-red-500'>{error.message}</div>}
    </div>
  )
}

export default TextArea
