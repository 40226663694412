// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { useState } from 'react'

type CaptionProps = {
  className?: string
  caption?: string
}

const MAX_VISIBLE_CHARACTERS = 125

const Caption = ({ className, caption }: CaptionProps) => {
  const [captionPartition, setCaptionPartition] = useState(
    caption?.slice(0, MAX_VISIBLE_CHARACTERS),
  )
  const [renderShowMoreButton, setRenderShowMoreButton] = useState(
    caption && caption.length > MAX_VISIBLE_CHARACTERS,
  )
  const _onShowMore = () => {
    setCaptionPartition(caption)
    setRenderShowMoreButton(false)
  }
  return (
    <p className={twMerge('text-sm font-medium text-gray-400', className)}>
      {captionPartition}
      {renderShowMoreButton && (
        <button className={'text-primary-400'} onClick={_onShowMore}>
          ...víc
        </button>
      )}
    </p>
  )
}

export default Caption
