// Tailwind Merge
import Button from '@/components/ui/Button'
import { twMerge } from 'tailwind-merge'

type InfoMessageContentProps = {
  className?: string
  heading: string
  message?: string
  actionButtonLabel?: string
  onActionButtonClick?: () => void
  actionButtonHref?: string
  emojiVariant?: '1' | '2' | '3' | '4'
}

const InfoMessageContent = ({
  className,
  heading,
  actionButtonLabel,
  message,
  onActionButtonClick,
  emojiVariant,
  actionButtonHref,
}: InfoMessageContentProps) => {
  const emojiNumber = emojiVariant || '1'

  return (
    <div className={twMerge('flex h-[70vh] flex-col items-center justify-center gap-3', className)}>
      <img src={`/img/emoji/emoji-${emojiNumber}.png`} alt='"crying emoji' className='max-w-64' />
      <h1 className='text-center text-2xl font-semibold'>{heading}</h1>
      <p className='subtitle max-w-[80%] text-center'>{message}</p>
      {(onActionButtonClick || actionButtonHref) && (
        <Button
          label={actionButtonLabel}
          className='mt-4'
          onClick={onActionButtonClick}
          href={actionButtonHref}
        />
      )}
    </div>
  )
}

export default InfoMessageContent
