import { axiosClient } from '@/lib/axios/axios.ts'
import { TOffer } from '@/types/offer.ts'
import API_ROUTES from '@/constants/apiRoutes.ts'

type TCreatePreRegistrationRequestData = {
  email: string
}

export const createPreRegistrationRequest = async (data: TCreatePreRegistrationRequestData) => {
  return axiosClient.post<TOffer>(API_ROUTES.PRE_REGISTRATION, data)
}
