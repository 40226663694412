// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { useMemo, useState } from 'react'
import { convertDuration, durationObjectToString } from '@/utils/date.ts'
import DurationSetModal from '@/components/modal/DurationSetModal'
import { createPortal } from 'react-dom'
import Button from '@/components/ui/Button'
import CustomTooltip from '@/components/ui/CustomTooltip'
import { IconClock } from '@tabler/icons-react'

type TimeAccessSettingInputProps = {
  className?: string
  duration: number | null
  onDurationChange?: (duration: number | null) => void
}

const TimeAccessSettingInput = ({
  className,
  duration,
  onDurationChange,
}: TimeAccessSettingInputProps) => {
  const [isSetDurationModalOpen, setIsSetDurationModalOpen] = useState(false)
  const durationObj = useMemo(() => {
    if (!duration) return undefined
    return convertDuration(duration)
  }, [duration])

  const _getSetDurationsButtonTitle = () => {
    if (!durationObj) return 'Nastavit'
    return durationObjectToString(durationObj)
  }

  const setDurationModal = (
    <DurationSetModal
      isOpened={isSetDurationModalOpen}
      onClose={() => setIsSetDurationModalOpen(false)}
      duration={duration}
      setDuration={onDurationChange}
    />
  )

  return (
    <div className={twMerge('flex flex-col gap-1', className)}>
      <label className={'flex items-center gap-2 text-sm'}>
        Časové omezení
        <CustomTooltip
          message={'Po uplynutí zadaného času nebude mít zákazník k souborům přístup.'}
        />
      </label>
      <div className={'flex gap-2'}>
        <Button
          label={'Neomezené'}
          variant={duration ? 'neutral' : 'secondary'}
          className={twMerge('flex-1', !duration && 'ring-2 ring-primary-500')}
          type={'button'}
          onClick={() => onDurationChange && onDurationChange(null)}
        />
        <Button
          variant={!duration ? 'neutral' : 'secondary'}
          label={_getSetDurationsButtonTitle()}
          className={twMerge('flex-1', duration && 'ring-2 ring-primary-500')}
          type={'button'}
          icon={!durationObj ? IconClock : undefined}
          onClick={() => setIsSetDurationModalOpen(true)}
        />
        {isSetDurationModalOpen && createPortal(setDurationModal, document.body)}
      </div>
    </div>
  )
}

export default TimeAccessSettingInput
