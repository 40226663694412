import PageHeading from '@/components/atom/PageHeading'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  useActivateOffer,
  useGetOfferPrivateById,
  useUpdateOffer,
} from '@/api/queries/offer.queries.ts'
import { ChangeEvent, useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { EOfferState } from '@/types/enum/offerState.ts'
import OfferDeactivateModal from '@/components/modal/OfferDeactivateModal'
import Button from '@/components/ui/Button'
import InfoMessage from '@/components/ui/InfoMessage'
import PageContainer from '@/components/ui/PageContainer'
import Input from '@/components/ui/Input'
import { durationToString } from '@/utils/date.ts'
import { captionSchema, offerNameSchema } from '@/utils/zod.ts'
import CustomTooltip from '@/components/ui/CustomTooltip'
import InfoMessageContent from '@/components/molecule/InfoMessageContent'
import { ROUTES } from '@/constants/routes.tsx'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import ScreenLoading from '@/components/atom/ScreenLoading'
import TextArea from '@/components/ui/TextArea'
import config, { inputsValidationConfig } from '@/constants/config.ts'
import VisibilityToggleGroup from '@/components/form/VisibilityToggleGroup'

const createOfferSchema = z.object({
  price: z
    .number({ required_error: 'Toto pole je povinné', invalid_type_error: 'Toto pole je povinné' })
    .min(config.MIN_PRICE / 100, `Minimální částka je ${config.MIN_PRICE / 100} kč`)
    .max(config.MAX_PRICE / 100, `Maximální částka je ${config.MAX_PRICE / 100} kč`),

  name: offerNameSchema,
  isPublic: z.boolean(),
  caption: captionSchema,
})

export type TEditOfferSchema = z.infer<typeof createOfferSchema>

const OfferSettingsPage = () => {
  const { id } = useParams()
  const { data: offerData, isLoading, isNotFound, isError } = useGetOfferPrivateById(id)
  const { mutateAsync: activateOffer, isPending: isActivationPending } = useActivateOffer()
  const { mutateAsync: updateOffer } = useUpdateOffer()
  const [isDeactivateModalOpened, setIsDeactivateModalOpened] = useState(false)
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<TEditOfferSchema>({
    resolver: zodResolver(createOfferSchema),
  })

  useEffect(() => {
    reset({
      price: offerData?.data?.price ? offerData.data.price / 100 : undefined,
      name: offerData?.data?.name,
      caption: offerData?.data?.caption,
      isPublic: offerData?.data?.isPublic,
    })
  }, [offerData, reset])

  if (isLoading) {
    return <ScreenLoading />
  }

  if (isNotFound) {
    return (
      <InfoMessageContent
        heading='Link nenalezen. 🤯'
        message='"Link" se nepodařilo najít, zkontroluj si správnost odkazu.'
        actionButtonHref={ROUTES.OFFERS}
        actionButtonLabel='Zpět ↩️'
      />
    )
  }

  if (!offerData?.data || isError) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.OFFERS} />
  }

  const { state, id: offerId, orderAccessSettings } = offerData.data

  const _onSubmit = handleSubmit(async data => {
    await updateOffer({
      offerId,
      ...data,
      price: data.price * 100,
    })
  })

  const _onOfferActivate = async () => {
    await activateOffer(id)
  }

  const _renderChangeStateButton = () => {
    if (state === EOfferState.DEACTIVATED) {
      return (
        <Button
          label={'Aktivovat link'}
          className={'w-full'}
          onClick={() => _onOfferActivate()}
          isLoading={isActivationPending}
        />
      )
    }
    return (
      <Button
        label={'Deaktivovat link'}
        className={'w-full'}
        variant={'neutral'}
        onClick={() => setIsDeactivateModalOpened(true)}
      />
    )
  }

  return (
    <PageContainer>
      <PageHeading
        backButton
        title={'Nastavení linku'}
        rightContent={
          <Button size={'small'} label={'Uložit'} disabled={!isDirty} onClick={_onSubmit} />
        }
      />
      <InfoMessage
        message={
          'Upravené změny mají efekt pouze na budoucí objednávky. Na uskutečněné objednávky se změny nevztahují'
        }
      />
      <div className={'flex flex-col gap-1'}>
        <div className={'flex items-center gap-1'}>
          <label className={'text-sm'}>Limit přístupu</label>
          <CustomTooltip
            id={'Limit'}
            message={'Po uplynutí zadaného času nebude mít zákazník k souborům přístup.'}
          />
        </div>
        <Button
          label={
            orderAccessSettings.orderAccessDurationLimit
              ? durationToString(orderAccessSettings.orderAccessDurationLimit)
              : 'Neomezeno'
          }
          disabled
          variant={'neutral'}
          className={'w-full  text-green-500'}
        />
      </div>
      <form className={'flex w-full flex-col gap-3'}>
        <Input
          id={'name'}
          label={'Název linku'}
          className={'w-full'}
          register={register}
          name={'name'}
          error={errors['name']}
        />
        <Controller
          control={control}
          name={'price'}
          render={({ field: { value, onChange } }) => (
            <Input
              id={'name'}
              label={'Cena'}
              className={'w-full'}
              error={errors['price']}
              type={'number'}
              value={value || undefined}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(Number(e.target.value))}
            />
          )}
        />
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextArea
              id={'caption'}
              label={'Popis'}
              maxCharacters={inputsValidationConfig.caption.maxLength}
              onChange={e => onChange(e.target.value)}
              value={value || undefined}
            />
          )}
          control={control}
          name={'caption'}
        />
        <VisibilityToggleGroup
          control={control}
          name={'isPublic'}
          label={
            <>
              Viditelnost linku
              <CustomTooltip message={'Určuje, zda li bude link vidět na tvém profilu'} />
            </>
          }
        />
      </form>
      {_renderChangeStateButton()}
      <OfferDeactivateModal
        offerId={id}
        isOpened={isDeactivateModalOpened}
        onClose={() => setIsDeactivateModalOpened(false)}
      />
    </PageContainer>
  )
}

export default OfferSettingsPage
