// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { IconInfoCircle } from '@tabler/icons-react'
import { Tooltip } from 'react-tooltip'

type TooltipProps = {
  className?: string
  message?: string
  id?: string
  tooltipClassname?: string
}

const CustomTooltip = ({ className, message, id, tooltipClassname }: TooltipProps) => {
  return (
    <>
      <div className={twMerge(`my-anchor-element${id}`, className)}>
        <IconInfoCircle />
      </div>
      <Tooltip
        className={twMerge('text-black', tooltipClassname)}
        anchorSelect={`.my-anchor-element${id}`}
        place='top'
        style={{ maxWidth: 'calc(100% - 20px)' }}
      >
        {message}
      </Tooltip>
    </>
  )
}

export default CustomTooltip
