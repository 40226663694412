import { useGetOrdersByOfferId } from '@/api/queries/order.queries.ts'
import OrderListItem from '@/components/molecule/OrderListItem'
import { useParams } from 'react-router-dom'
import PageContainer from '@/components/ui/PageContainer'
import OfferStats from '@/components/molecule/OfferStats'
import PageHeading from '@/components/atom/PageHeading'
import InfiniteScrollList from '@/components/molecule/InfiniteScrollList'
import { EOrderPaymentStatus } from '@/types/orderPaymentStatus'
import { useGetOfferPrivateById } from '@/api/queries/offer.queries'

import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import { ROUTES } from '@/constants/routes.tsx'
import ScreenLoading from '@/components/atom/ScreenLoading'

const OfferOrdersPage = () => {
  const { id } = useParams()
  const { data: offerData, isLoading } = useGetOfferPrivateById(id)
  const { data, fetchNextPage, hasNextPage, reset, isPending, error } = useGetOrdersByOfferId(
    id,
    20,
    EOrderPaymentStatus.SUCCEED,
  )

  if (isLoading) {
    return <ScreenLoading />
  }

  if (!offerData || error) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.OFFERS} />
  }

  const offer = offerData.data
  return (
    <>
      <PageContainer>
        <PageHeading backButton title={'Objednávky'} />
        <OfferStats
          // revenues total always accessible in private offer
          revenuesTotal={offer.revenuesTotal as number}
          successfulOrdersCount={offer.successfullOrdersCount}
        />
        <InfiniteScrollList
          dataLength={data?.pages.reduce((acc, order) => acc + order.data.length, 0)}
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          refreshFunction={() => reset()}
          isLoading={isPending}
        >
          {data?.pages?.map(page =>
            page.data.map(order => <OrderListItem key={order.id} order={order} />),
          )}
        </InfiniteScrollList>
      </PageContainer>
    </>
  )
}

export default OfferOrdersPage
