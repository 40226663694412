// Tailwind Merge
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'
import { IconX } from '@tabler/icons-react'
import Button from '@/components/ui/Button'

type BottomPopoverProps = {
  className?: string
  onClose?: () => void
  isOpen?: boolean
  title?: string
  children?: React.ReactNode
}

const framerVariantsContent = {
  hidden: { y: '100%' },
  visible: { y: '4rem' },
}

const framerVariantsBackground = {
  hidden: { opacity: 0 },
  visible: { opacity: 0.3 },
}

const BottomPopover = ({ className, isOpen, title, children, onClose }: BottomPopoverProps) => {
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <div className={'absolute top-0 h-full w-full overflow-hidden'}>
            <motion.div
              initial={'hidden'}
              exit={'hidden'}
              animate={isOpen ? 'visible' : 'hidden'}
              variants={framerVariantsBackground}
              className='absolute top-0 z-10 h-full w-full bg-black'
            />

            <motion.div
              initial={'hidden'}
              exit={'hidden'}
              animate={'visible'}
              variants={framerVariantsContent}
              className={twMerge(
                'absolute bottom-0 z-20 flex w-full flex-col gap-3 rounded-t-3xl  bg-white  px-4 pb-20 pt-10 drop-shadow-lg ',
                className,
              )}
            >
              <Button
                icon={IconX}
                size={'small'}
                variant={'plain'}
                type={'button'}
                className={'absolute left-3 top-3 text-gray-600'}
                onClick={() => onClose && onClose()}
              />
              {title && <h2 className={'text-center text-xl font-semibold'}>{title}</h2>}
              {children}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

export default BottomPopover
