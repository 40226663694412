// Tailwind Merge
import { twMerge } from 'tailwind-merge'

type CircularLoadingProps = {
  className?: string
  size?: number
  color?: 'primary' | 'neutral'
}

const CircularLoading = ({ className, size, color }: CircularLoadingProps) => {
  return (
    <div
      className={twMerge(
        color === 'neutral' && 'border-surface-50-500',
        (color === 'primary' || !color) && 'border-primary-500',
        ' inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid  border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
        className,
      )}
      role='status'
      style={{ width: size || 30, height: size || 30, borderRadius: '100%' }}
    />
  )
}

export default CircularLoading
