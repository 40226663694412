import { axiosClient } from '@/lib/axios/axios.ts'
import API_ROUTES, { getApiRoute } from '@/constants/apiRoutes.ts'
import { TPasswordReset } from '@/types/passwordReset.ts'

type TCreatePasswordReset = {
  email: string
}

type TResetPasswordApiInterface = {
  newPassword: string
  passwordResetId: string
}
export const createPasswordReset = async (data: TCreatePasswordReset) => {
  return axiosClient.post(API_ROUTES.PASSWORD_RESET, data)
}

export const getPasswordResetById = async (id: string) => {
  return axiosClient.get<TPasswordReset>(
    getApiRoute(API_ROUTES.PASSWORD_RESET_DETAIL, { passwordResetId: id }),
  )
}

export const resetPassword = async (data: TResetPasswordApiInterface) => {
  return axiosClient.post(API_ROUTES.PASSWORD_RESET_ACTION, data)
}
