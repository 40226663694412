// Tailwind Merge
import { formatDateToString } from '@/utils/date.ts'
import OrderStateBox from '@/components/atom/OrderStateBox'
import { ROUTES } from '@/constants/routes.tsx'
import ListItem from '@/components/ui/ListItem'
import { TOrder } from '@/types/order.ts'
import { formatPrice } from '@/utils/price.ts'

type OrderListItemProps = {
  order: TOrder
}

const OrderListItem = ({ order }: OrderListItemProps) => {
  return (
    <ListItem
      className={'block'}
      href={`${ROUTES.ORDER_MANAGEMENT_DETAIL}/${order.id}`}
      leftContent={
        <>
          <OrderStateBox state={order.paymentStatus} />
        </>
      }
      rightContent={
        <>
          <span className={'text-md -mt-1 font-semibold'}>{order.fullName || '--'}</span>
          <span className={'font-semibold text-primary-500'}>
            +{formatPrice(order.price - (order.fee as number))} Kč
          </span>
          <div className={'flex flex-wrap items-center  gap-2'}>
            <span className={'text-sm text-gray-500'}>{order.email}</span> •
            <span className={'text-sm text-gray-500'}>{formatDateToString(order.createdAt)}</span>
          </div>
        </>
      }
    />
  )
}

export default OrderListItem
