// Tailwind Merge

import Modal from '@/components/modal/Modal'
import { useMemo, useState } from 'react'
import { convertDuration } from '@/utils/date.ts'
import Button from '@/components/ui/Button'
import { inputsValidationConfig } from '@/constants/config.ts'

const DIGITS_REGEX = /^\d{0,4}$/

type OfferDeactivateModalProps = {
  className?: string
  isOpened?: boolean
  onClose?: () => void
  duration?: number | null
  setDuration?: (duration: number | null) => void
}

const DurationSetModal = ({
  onClose,
  isOpened,
  duration,
  setDuration,
}: OfferDeactivateModalProps) => {
  const durationObj = useMemo(() => {
    if (!duration) return undefined
    return convertDuration(duration)
  }, [duration])

  const [days, setDays] = useState<number>(durationObj?.days || 0)
  const [hours, setHours] = useState<number>(durationObj?.hours || 0)
  const [minutes, setMinutes] = useState<number>(durationObj?.minutes || 0)
  const [error, setError] = useState<string | undefined>()

  const _renderInput = (label: string, value: string | number, onChange: (num: number) => void) => {
    return (
      <div className={'flex flex-col items-center justify-center gap-3'}>
        <input
          min='0'
          max='9999'
          value={value}
          onChange={e => {
            if (!DIGITS_REGEX.test(e.target.value)) return
            onChange(Number(e.target.value))
          }}
          maxLength={3}
          pattern='[0-9]{4}'
          className={
            'h-20 w-16 rounded-xl border-2 border-primary-500 text-center text-3xl font-semibold'
          }
          inputMode='decimal'
          placeholder={'00'}
        />
        <label className={'font-semibold'}>{label}</label>
      </div>
    )
  }

  const _onSet = () => {
    const duration = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60

    if (duration < inputsValidationConfig.orderAccessDurationLimit.min) {
      const newDurationObj = convertDuration(inputsValidationConfig.orderAccessDurationLimit.min)
      setError(
        `Minimální délka omezení je ${newDurationObj.days ? `${newDurationObj.days}d ` : ''}${newDurationObj.hours ? `${newDurationObj.hours}h ` : ''}${newDurationObj.minutes ? `${newDurationObj.minutes}m ` : ''}`,
      )
      return
    }

    if (
      inputsValidationConfig.orderAccessDurationLimit.max &&
      duration > inputsValidationConfig.orderAccessDurationLimit.max
    ) {
      const newDurationObj = convertDuration(inputsValidationConfig.orderAccessDurationLimit.max)
      setError(
        `Maximální délka omezení je ${newDurationObj.days ? `${newDurationObj.days}d ` : ''}${newDurationObj.hours ? `${newDurationObj.hours}h ` : ''}${newDurationObj.minutes ? `${newDurationObj.minutes}m ` : ''}`,
      )
      return
    }

    setDuration && setDuration(duration)
    onClose && onClose()
  }

  return (
    <Modal onClose={onClose} isOpened={isOpened}>
      <div className={'flex flex-col items-center justify-center gap-8'}>
        <div className={'flex justify-center gap-8'}>
          {_renderInput('Dny', days, setDays)}
          {_renderInput('Hodiny', hours, setHours)}
          {_renderInput('Minuty', minutes, setMinutes)}
        </div>
        {error && <span className={'text-sm text-red-600'}>{error}</span>}
        <Button label={'Nastavit'} className={'w-full'} onClick={_onSet} />
      </div>
    </Modal>
  )
}

export default DurationSetModal
