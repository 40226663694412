import { useGetOfferPrivateById } from '@/api/queries/offer.queries.ts'
import { useParams } from 'react-router-dom'
import FileThumbnail from '@/components/molecule/FilePreviewThumbnail'
import PageHeading from '@/components/atom/PageHeading'
import OfferShareActions from '@/components/atom/OfferShareActions'
import { EOfferUploadStatus } from '@/types/enum/offerUploadStatus.ts'
import AlertCard from '@/components/ui/AlertCard'
import PageContainer from '@/components/ui/PageContainer'
import GlobalErrorInfoMessageContent from '@/components/molecule/GlobalErrorInfoMessageContent'
import { ROUTES } from '@/constants/routes.tsx'
import ScreenLoading from '@/components/atom/ScreenLoading'

const OfferSharePage = () => {
  const { id } = useParams()
  const { data, isLoading, error } = useGetOfferPrivateById(id)

  if (isLoading) {
    return <ScreenLoading />
  }

  if (!data?.data || error) {
    return <GlobalErrorInfoMessageContent backLink={ROUTES.DASHBOARD} />
  }

  const offer = data.data
  const file = offer.files[0]

  return (
    <PageContainer>
      <PageHeading title={'Link🔍'} style={{ flex: 0 }} backButton />
      <h2 className={'text-center text-lg font-bold'}>{offer.name}</h2>
      <div className={'flex items-center justify-center'}>
        <FileThumbnail file={file} price={offer.price} type={'locked'} showLock />
      </div>
      {offer.uploadStatus === EOfferUploadStatus.PROCESSING ? (
        <AlertCard
          content={'Probíhá zpracování médií, vrať se prosím k vytvořenému linku později'}
          className={'w-full'}
        />
      ) : (
        <>
          <h2 className={'text-center text-lg font-semibold'}>Sdílej link🔗</h2>
          <OfferShareActions offer={data.data} className={'w-full'} />
        </>
      )}
    </PageContainer>
  )
}

export default OfferSharePage
